/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-const */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { Box, Grid, Icon, IconButton, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { VTextField, VForm, useVForm, VTextFieldData, VFieldMoney, BasicSelect, AutoCompleteProdutor, AutoCompleteFazenda, AutoCompleteTransportadora, AutoCompleteVeiculos, AutoCompleteParceiro, IVFormErrors } from '../../../shared/forms';
import { FerramentasDeDetalheRomaneio } from '../../../shared/components';
import { LayoutBaseDePaginaNfe } from '../../../shared/layouts';

import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { v4 as uuidv4 } from 'uuid';
import { IRomaneio, RomaneioService } from '../../../shared/services/api/faturamento/romaneios/RomaneioService';
import { DialogItensRomaneios } from './DialogItensRomaneios';
import { DialoFecharRomaneio } from './DialogFecharRomaneio';
import PDFRomaneio from './ImpressaoRomaneio';
import { AutoCompleteContaBancaria } from '../../../shared/forms/AutoCompleteContaBancaria';

interface IItens{
  iditensrom: string;
  idfruta: string;
  idfazenda: string;
  descricao: string;
  qtd: number;
  preco: number;
  total: number;
  un: string;
  conversor: number;
  qtdajuste: number;
  subtotal: number;
  totaldesconto: number;
}

interface IItensids{
  iditensrom: string;
}

const formValidationSchema: yup.ObjectSchema<Omit<IRomaneio, 'numero' | 'datafechado'>> = yup.object().shape({
  idparceiro: yup.string().required(),
  idprodutor: yup.number().optional().default(0),
  idfazenda: yup.string().required(),
  idconta: yup.number().optional().default(0),
  idtransportadora: yup.string().nullable().matches(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/, 'Deve ser um UUID válido'),
  idveiculo: yup.string().nullable().matches(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/, 'Deve ser um UUID válido'),
  dataemissao: yup.date().required(),
  dataoperacao: yup.date().optional().default(new Date()),
  vencimento: yup.date().required(),
  tipobalanca: yup.number().required().min(1).max(2),
  tiposeguro: yup.number().required().min(1).max(2),
  tipoicmsfrete: yup.number().required().min(1).max(2),
  tipofunrural: yup.number().required().min(1).max(2),
  tipofrete: yup.number().required().min(1).max(2),
  frete: yup.number().required(),
  icms: yup.number().required(),
  balanca: yup.number().required(),
  icmsmercaodira: yup.number().required(),
  seguro: yup.number().required(),
  funrural: yup.number().required(),
  valoritens: yup.number().required(),
  valortotal: yup.number().required(),
  descontofrutas: yup.number().required(),
  numeronfe: yup.string().optional().default(''),  
  valornfe: yup.number().required(),
  outros: yup.number().required(),
  obs: yup.string().optional().default(''),  
  status: yup.boolean().optional().default(false),
  romaneionfe: yup.boolean().optional().default(false),
});
  
export const DetalheDaRomaneio: React.FC = () => {  
  const { id = 'novo' } = useParams<'id'>();
  const navigate = useNavigate();

  const { formRef, save, saveAndClose, isSaveAndClose } = useVForm();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openfechar, setOpenFechar] = useState(false);
  const [statuss, setStatuss] = useState(false);
  const [value, setValue] = useState('');
  const [valueCancelar, setValueCancelar] = useState('');
  const [iditens, setIditens] = useState('');
  const [transportadora, setTransportadora] = useState('');
  const [rows, setRows] = useState<IItens[]>([]);

  const [rowsids, setRowsids] = useState<IItensids[]>([]);
  
  const [dadositens, setDadositens] = useState<IItens>();

  const guuid = uuidv4();
  
    
  useEffect(() => {
    if (id !== 'novo') {
      setIsLoading(true);
      RomaneioService.getById(id)
        .then((result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            alert(result.message);
            navigate('/romaneios');
          } else {   
            formRef.current?.setData(result);
            setRows(result.itens);
            setStatuss(result.status);
          }
        });
    } else {
      setRows([]);
      setStatuss(false);
      formRef.current?.setData({
        dataemissao: new Date(),
        vencimento: new Date(),
        datafechado: '',
        tipobalanca: 1,
        tiposeguro: 1,  
        tipoicmsfrete: 1,
        tipofunrural: 1,  
        tipofrete: 1,
        frete: 0,         
        icms: 0,  
        balanca: 0,    
        icmsmercaodira: 0,
        seguro: 0,  
        funrural: 0,    
        valoritens: 0,                
        desconto: 0,   
        valortotal: 0,
        descontofrutas: 0,  
        valornfe: 0,
        outros: 0,
        numeronfe: '',     
        obs: '',  
        romaneionfe: false,  
        numero: '',
      });     
    }  
  }, [id]); 
  
 
  const handleSave = (dados: IRomaneio) => {
    formValidationSchema.
      validate(dados, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);

        if (id === 'novo') {
          RomaneioService
            .create(dadosValidados, rows)
            .then((result) => {
              setIsLoading(false);

              if (result instanceof Error) {
                alert(result.message);
              } else {
                if (isSaveAndClose()) {
                  navigate('/romaneios');  
                } else {
                  navigate(`/romaneios/detalhe/${result}`);
                }
              }
            });
        } else {
          RomaneioService
            .updateById(id, dadosValidados, rows, rowsids)
            .then((result) => {
              setIsLoading(false);

              if (result instanceof Error) {
                alert(result.message);
              } else {
                if (isSaveAndClose()) {
                  navigate('/romaneios');
                }
              }
            });
        }
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};
        errors.inner.forEach(error => {
          if (!error.path) return;

          validationErrors[error.path] = error.message;
        });

        formRef.current?.setErrors(validationErrors);
      });
  };

  const handleDelete = (id: string) => {
    if (confirm('Realmente deseja apagar?')) {
 
      let meuObjeto = {iditensrom: id};
     
      rowsids.push(meuObjeto); 
      
     const indexToDelete = rows.findIndex((item) => item.iditensrom === id);
      if (indexToDelete !== -1) {
        const updatedList = [...rows];
        updatedList.splice(indexToDelete, 1);
        setRows(updatedList);
        const totais = calcularTotais(updatedList);
        const data = formRef.current?.getData();
        const tvprod = totais.tvprod;
        const tdesconto = totais.tdesconto;
        const icmsmercaodira =  Number(data?.icmsmercaodira);
        const outros =  Number(data?.outros);
        const balnca = data?.tipobalanca === '1' ? 0 : Number(data?.balanca);
        const icmsfrete = data?.tipoicmsfrete === '1' ? 0 : Number(data?.icms);
        const seguro = data?.tiposeguro === '1' ? 0 : Number(data?.seguro);
        const funrural = data?.tipofunrural=== '1' ? 0 : Number(data?.funrural);
        const frete = data?.tipofrete === '1' ? 0 : Number(data?.frete);
        const ttotal = totais.tvprod + + frete + balnca + icmsfrete + seguro + funrural + icmsmercaodira + outros - totais.tdesconto;
        Object.entries(totais).forEach(([campo, valor]) => {
          formRef.current?.setFieldValue(campo, valor);
        });
        formRef.current?.setFieldValue('valoritens', tvprod);
        formRef.current?.setFieldValue('descontofrutas', tdesconto);
        formRef.current?.setFieldValue('valortotal', ttotal);
      }
    }
  };

  const handleuuidtransportadora = (uuid: string | undefined) => {
    if (uuid != null) {
      setTransportadora(uuid);
    }
  };
   
  const handleClose = (newValue?: string) => {
    setOpen(false);

    if (newValue) {
      setValue(newValue);
    }
  };

  const handleCloseFechar = (newValue?: string) => {
    setOpenFechar(false);

    if (newValue) {
      setValueCancelar(newValue);
    }
  };

  type RowsType = IItens[];

  const calcularTotais = (lista: RowsType) => {
    const tvprod = lista.reduce((acc, item) => acc + item.subtotal, 0);
    const tdesconto = lista.reduce((acc, item) => acc + item.totaldesconto, 0);
    return {
      tvprod,
      tdesconto,
    };
  };

  const handleBuscarDados = (id: string) => {
    const updatedList = rows.map((listItem) => {
      if (listItem.iditensrom === id) {
        setDadositens(listItem);
      }
    });
  };
  
const handleItens = (dados: IItens) => {
  const index = rows.findIndex((listItem) => listItem.iditensrom === dados.iditensrom);

  let updatedList: RowsType;

  if (index !== -1) {
    updatedList = [...rows];
    updatedList[index] = dados;
  } else {
    updatedList = [...rows, dados]; 
  }
  setRows(updatedList);
  const data = formRef.current?.getData();
  const totais = calcularTotais(updatedList);
  const tvprod = totais.tvprod;
  const tdesconto = totais.tdesconto;
  const icmsmercaodira =  Number(data?.icmsmercaodira);
  const outros =  Number(data?.outros);
  const balnca = data?.tipobalanca === '1' ? 0 : Number(data?.balanca);
  const icmsfrete = data?.tipoicmsfrete === '1' ? 0 : Number(data?.icms);
  const seguro = data?.tiposeguro === '1' ? 0 : Number(data?.seguro);
  const funrural = data?.tipofunrural=== '1' ? 0 : Number(data?.funrural);
  const frete = data?.tipofrete === '1' ? 0 : Number(data?.frete);
  const ttotal = totais.tvprod + + frete + balnca + icmsfrete + seguro + funrural + icmsmercaodira + outros - totais.tdesconto;
  Object.entries(totais).forEach(([campo, valor]) => {
    formRef.current?.setFieldValue(campo, valor);
  });  
  formRef.current?.setFieldValue('valoritens', tvprod);  
  formRef.current?.setFieldValue('descontofrutas', tdesconto);
  formRef.current?.setFieldValue('valortotal', ttotal);
};

const handlecalcularfrete = (valor: string | undefined) => {
  if (valor != null) {
    valor = valor.replace(/\D/g, '');
    valor = valor.replace(/(\d)(\d{2})$/, '$1.$2');
    valor = valor.replace(/(?=(\d{3})+(\D))\B/g, '');
    const data = formRef.current?.getData();
    const tprod= data?.valoritens;
    const tdescontofruta= data?.descontofrutas;
    const icmsmercaodira =  Number(data?.icmsmercaodira);
    const outros =  Number(data?.outros);
    const balnca = data?.tipobalanca === '1' ? 0 : Number(data?.balanca);
    const icmsfrete = data?.tipoicmsfrete === '1' ? 0 : Number(data?.icms);
    const seguro = data?.tiposeguro === '1' ? 0 : Number(data?.seguro);
    const funrural = data?.tipofunrural=== '1' ? 0 : Number(data?.funrural);
    const tipofrete = data?.tipofrete;
    if(tipofrete == 2){
      const tfrete = Number(valor);
      const valortotal = Number(tprod) + tfrete + balnca + icmsfrete + seguro + funrural + icmsmercaodira + outros - Number(tdescontofruta);
      formRef.current?.setFieldValue('valortotal', valortotal);
    }
  }
}; 

const handletipofrete = (valor: string | undefined) => {
  if (valor != null) {
    if(valor === '1'){
      const data = formRef.current?.getData();
      const tprod= data?.valoritens;
      const tdescontofruta= data?.descontofrutas;
      const icmsmercaodira =  Number(data?.icmsmercaodira);
      const outros =  Number(data?.outros);
      const balnca = data?.tipobalanca === '1' ? 0 : Number(data?.balanca);
      const icmsfrete = data?.tipoicmsfrete === '1' ? 0 : Number(data?.icms);
      const seguro = data?.tiposeguro === '1' ? 0 : Number(data?.seguro);
      const funrural = data?.tipofunrural=== '1' ? 0 : Number(data?.funrural);
      const valortotal = Number(tprod) +  balnca + icmsfrete + seguro + funrural + icmsmercaodira + outros - Number(tdescontofruta);
      formRef.current?.setFieldValue('valortotal', valortotal);
    }else{
      const data = formRef.current?.getData();
      const tprod= data?.valoritens;
      const tdescontofruta= data?.descontofrutas;
      const icmsmercaodira =  Number(data?.icmsmercaodira);
      const outros =  Number(data?.outros);
      const balnca = data?.tipobalanca === '1' ? 0 : Number(data?.balanca);
      const icmsfrete = data?.tipoicmsfrete === '1' ? 0 : Number(data?.icms);
      const seguro = data?.tiposeguro === '1' ? 0 : Number(data?.seguro);
      const funrural = data?.tipofunrural=== '1' ? 0 : Number(data?.funrural);
      const vfrete = data?.frete;
      const valortotal = Number(tprod) + Number(vfrete) + balnca + icmsfrete + seguro + funrural + icmsmercaodira + outros - Number(tdescontofruta);
      formRef.current?.setFieldValue('valortotal', valortotal);

    }    
  }
};

const handlecalcularbalanca = (valor: string | undefined) => {
  if (valor != null) {
    valor = valor.replace(/\D/g, '');
    valor = valor.replace(/(\d)(\d{2})$/, '$1.$2');
    valor = valor.replace(/(?=(\d{3})+(\D))\B/g, '');
    const data = formRef.current?.getData();
    const tprod= data?.valoritens;
    const tdescontofruta= data?.descontofrutas;
    const tipobalanca = data?.tipobalanca;
    const icmsmercaodira =  Number(data?.icmsmercaodira);
    const outros =  Number(data?.outros);
    const frete = data?.tipofrete === '1' ? 0 : Number(data?.frete);
    const icmsfrete = data?.tipoicmsfrete === '1' ? 0 : Number(data?.icms);
    const seguro = data?.tiposeguro === '1' ? 0 : Number(data?.seguro);
    const funrural = data?.tipofunrural=== '1' ? 0 : Number(data?.funrural);
    if(tipobalanca == 2){
      const tbanaca = Number(valor);
      const valortotal = Number(tprod) + frete + tbanaca + icmsfrete + seguro + funrural + icmsmercaodira + outros - Number(tdescontofruta);
      formRef.current?.setFieldValue('valortotal', valortotal);
    }
  }
}; 

const handletipobalanca = (valor: string | undefined) => {
  if (valor != null) {
    if(valor === '1'){
      const data = formRef.current?.getData();
      const tprod= data?.valoritens;
      const tdescontofruta= data?.descontofrutas;
      const icmsmercaodira =  Number(data?.icmsmercaodira);
      const outros =  Number(data?.outros);
      const frete = data?.tipofrete === '1' ? 0 : Number(data?.frete);
      const icmsfrete = data?.tipoicmsfrete === '1' ? 0 : Number(data?.icms);
      const seguro = data?.tiposeguro === '1' ? 0 : Number(data?.seguro);
      const funrural = data?.tipofunrural=== '1' ? 0 : Number(data?.funrural);
      const valortotal = Number(tprod) + frete + icmsfrete + seguro + funrural + icmsmercaodira + outros - Number(tdescontofruta);
      formRef.current?.setFieldValue('valortotal', valortotal);
    }else{
      const data = formRef.current?.getData();
      const tprod= data?.valoritens;
      const tdescontofruta= data?.descontofrutas;
      const icmsmercaodira =  Number(data?.icmsmercaodira);
      const outros =  Number(data?.outros);
      const vbalanca = data?.balanca;
      const frete = data?.tipofrete === '1' ? 0 : Number(data?.frete);
      const icmsfrete = data?.tipoicmsfrete === '1' ? 0 : Number(data?.icms);
      const seguro = data?.tiposeguro === '1' ? 0 : Number(data?.seguro);
      const funrural = data?.tipofunrural=== '1' ? 0 : Number(data?.funrural);
      const valortotal = Number(tprod) + Number(vbalanca) + frete + icmsfrete + seguro + funrural + icmsmercaodira + outros - Number(tdescontofruta);
      formRef.current?.setFieldValue('valortotal', valortotal);

    }    
  }
}; 

const handlecalcularicmsfrete = (valor: string | undefined) => {
    if (valor != null) {
      valor = valor.replace(/\D/g, '');
      valor = valor.replace(/(\d)(\d{2})$/, '$1.$2');
      valor = valor.replace(/(?=(\d{3})+(\D))\B/g, '');
      const data = formRef.current?.getData();
      const tprod= data?.valoritens;
      const icmsmercaodira =  Number(data?.icmsmercaodira);
      const outros =  Number(data?.outros);
      const tdescontofruta= data?.descontofrutas;
      const tipoicmsfrete = data?.tipoicmsfrete;
      const frete = data?.tipofrete === '1' ? 0 : Number(data?.frete);
      const balnca = data?.tipobalanca === '1' ? 0 : Number(data?.balanca);
      const seguro = data?.tiposeguro === '1' ? 0 : Number(data?.seguro);
      const funrural = data?.tipofunrural=== '1' ? 0 : Number(data?.funrural);
      if(tipoicmsfrete  == 2){
        const ticmsfrete = Number(valor);
        const valortotal = Number(tprod) + frete + ticmsfrete + balnca + seguro + funrural + icmsmercaodira + outros - Number(tdescontofruta);
        formRef.current?.setFieldValue('valortotal', valortotal);
      }
    }              
  };               

  const handletipoicmsfrete = (valor: string | undefined) => {
    if (valor != null) {
      if(valor === '1'){
        const data = formRef.current?.getData();
        const tprod= data?.valoritens;
        const icmsmercaodira =  Number(data?.icmsmercaodira);
        const outros =  Number(data?.outros);
        const tdescontofruta= data?.descontofrutas;
        const frete = data?.tipofrete === '1' ? 0 : Number(data?.frete);
        const balnca = data?.tipobalanca === '1' ? 0 : Number(data?.balanca);
        const seguro = data?.tiposeguro === '1' ? 0 : Number(data?.seguro);
        const funrural = data?.tipofunrural=== '1' ? 0 : Number(data?.funrural);
        const valortotal = Number(tprod) + frete + balnca + seguro + funrural + icmsmercaodira + outros - Number(tdescontofruta);
        formRef.current?.setFieldValue('valortotal', valortotal);
      }else{
        const data = formRef.current?.getData();
        const tprod= data?.valoritens;
        const icmsmercaodira =  Number(data?.icmsmercaodira);
        const outros =  Number(data?.outros);
        const tdescontofruta= data?.descontofrutas;
        const vicmsfrete = data?.icms;
        const frete = data?.tipofrete === '1' ? 0 : Number(data?.frete);
        const balnca = data?.tipobalanca === '1' ? 0 : Number(data?.balanca);
        const seguro = data?.tiposeguro === '1' ? 0 : Number(data?.seguro);
        const funrural = data?.tipofunrural=== '1' ? 0 : Number(data?.funrural);
        const valortotal = Number(tprod) + Number(vicmsfrete) + frete + balnca + seguro + funrural + icmsmercaodira + outros - Number(tdescontofruta);
        formRef.current?.setFieldValue('valortotal', valortotal);

      }    
    }
  };  

  const handlecalcularseguro = (valor: string | undefined) => {
    if (valor != null) {
      valor = valor.replace(/\D/g, '');
      valor = valor.replace(/(\d)(\d{2})$/, '$1.$2');
      valor = valor.replace(/(?=(\d{3})+(\D))\B/g, '');
      const data = formRef.current?.getData();
      const tprod= data?.valoritens;
      const icmsmercaodira =  Number(data?.icmsmercaodira);
      const outros =  Number(data?.outros);
      const tdescontofruta= data?.descontofrutas;
      const tiposeguro = data?.tiposeguro;
      const frete = data?.tipofrete === '1' ? 0 : Number(data?.frete);
      const icmsfrete = data?.tipoicmsfrete === '1' ? 0 : Number(data?.icms);
      const balnca = data?.tipobalanca === '1' ? 0 : Number(data?.balanca);
      const funrural = data?.tipofunrural=== '1' ? 0 : Number(data?.funrural);
      if(tiposeguro == 2){
        const tseguro = Number(valor);
        const valortotal = Number(tprod) + frete + tseguro + icmsfrete + balnca + funrural + icmsmercaodira + outros - Number(tdescontofruta);
        formRef.current?.setFieldValue('valortotal', valortotal);
      }
    }
  };  

  const handletiposeguro = (valor: string | undefined) => {
    if (valor != null) {
      if(valor === '1'){
        const data = formRef.current?.getData();
        const tprod= data?.valoritens;
        const icmsmercaodira =  Number(data?.icmsmercaodira);
        const outros =  Number(data?.outros);
        const tdescontofruta= data?.descontofrutas;
        const frete = data?.tipofrete === '1' ? 0 : Number(data?.frete);
        const balnca = data?.tipobalanca === '1' ? 0 : Number(data?.balanca);
        const funrural = data?.tipofunrural=== '1' ? 0 : Number(data?.funrural);
        const valortotal = Number(tprod) + frete + balnca + funrural + icmsmercaodira + outros - Number(tdescontofruta);
        formRef.current?.setFieldValue('valortotal', valortotal);
      }else{
        const data = formRef.current?.getData();
        const tprod= data?.valoritens;
        const icmsmercaodira =  Number(data?.icmsmercaodira);
        const outros =  Number(data?.outros);
        const tdescontofruta= data?.descontofrutas;
        const vseguro = data?.seguro;
        const frete = data?.tipofrete === '1' ? 0 : Number(data?.frete);
        const icmsfrete = data?.tipoicmsfrete === '1' ? 0 : Number(data?.icms);
        const balnca = data?.tipobalanca === '1' ? 0 : Number(data?.balanca);
        const funrural = data?.tipofunrural=== '1' ? 0 : Number(data?.funrural);
        const valortotal = Number(tprod) + Number(vseguro) + frete + icmsfrete + balnca + funrural + icmsmercaodira + outros - Number(tdescontofruta);
        formRef.current?.setFieldValue('valortotal', valortotal);
      }    
    }
  };  

  const handlecalcularfunrural = (valor: string | undefined) => {
    if (valor != null) {
      valor = valor.replace(/\D/g, '');
      valor = valor.replace(/(\d)(\d{2})$/, '$1.$2');
      valor = valor.replace(/(?=(\d{3})+(\D))\B/g, '');
      const data = formRef.current?.getData();
      const tprod= data?.valoritens;
      const icmsmercaodira =  Number(data?.icmsmercaodira);
      const outros =  Number(data?.outros);
      const tdescontofruta= data?.descontofrutas;
      const tipofunrural = data?.tipofunrural;
      const frete = data?.tipofrete === '1' ? 0 : Number(data?.frete);
      const icmsfrete = data?.tipoicmsfrete === '1' ? 0 : Number(data?.icms);
      const seguro = data?.tiposeguro === '1' ? 0 : Number(data?.seguro);
      const balnca = data?.tipobalanca === '1' ? 0 : Number(data?.balanca);
      if(tipofunrural == 2){
        const tfunrural = Number(valor);
        const valortotal = Number(tprod) + frete + tfunrural + icmsfrete + seguro + balnca + icmsmercaodira + outros - Number(tdescontofruta);
        formRef.current?.setFieldValue('valortotal', valortotal);
      }
    }
  };  

  const handletipofunrural = (valor: string | undefined) => {
    if (valor != null) {
      if(valor === '1'){
        const data = formRef.current?.getData();
        const tprod= data?.valoritens;
        const icmsmercaodira =  Number(data?.icmsmercaodira);
        const outros =  Number(data?.outros);
        const tdescontofruta= data?.descontofrutas;
        const frete = data?.tipofrete === '1' ? 0 : Number(data?.frete);
        const icmsfrete = data?.tipoicmsfrete === '1' ? 0 : Number(data?.icms);
        const seguro = data?.tiposeguro === '1' ? 0 : Number(data?.seguro);
        const balnca = data?.tipobalanca === '1' ? 0 : Number(data?.balanca);
        const valortotal = Number(tprod) + frete + icmsfrete + seguro + balnca + icmsmercaodira + outros - Number(tdescontofruta);
        formRef.current?.setFieldValue('valortotal', valortotal);
      }else{
        const data = formRef.current?.getData();
        const tprod= data?.valoritens;
        const icmsmercaodira =  Number(data?.icmsmercaodira);
        const outros =  Number(data?.outros);
        const tdescontofruta= data?.descontofrutas;
        const funrural = data?.funrural;
        const frete = data?.tipofrete === '1' ? 0 : Number(data?.frete);
        const icmsfrete = data?.tipoicmsfrete === '1' ? 0 : Number(data?.icms);
        const seguro = data?.tiposeguro === '1' ? 0 : Number(data?.seguro);
        const balnca = data?.tipobalanca === '1' ? 0 : Number(data?.balanca);
        const valortotal = Number(tprod) + Number(funrural) + frete + icmsfrete + seguro + balnca  + icmsmercaodira + outros - Number(tdescontofruta);
        formRef.current?.setFieldValue('valortotal', valortotal);
      }    
    }
  };  

  const handlecalcularicmsmercadoria = (valor: string | undefined) => {
    if (valor != null) {
      valor = valor.replace(/\D/g, '');
      valor = valor.replace(/(\d)(\d{2})$/, '$1.$2');
      valor = valor.replace(/(?=(\d{3})+(\D))\B/g, '');
      const data = formRef.current?.getData();
      const tprod= data?.valoritens;
      const outros =  Number(data?.outros);
      const tdescontofruta= data?.descontofrutas;
      const funrural = data?.tipofunrural=== '1' ? 0 : Number(data?.funrural);
      const frete = data?.tipofrete === '1' ? 0 : Number(data?.frete);
      const icmsfrete = data?.tipoicmsfrete === '1' ? 0 : Number(data?.icms);
      const seguro = data?.tiposeguro === '1' ? 0 : Number(data?.seguro);
      const balnca = data?.tipobalanca === '1' ? 0 : Number(data?.balanca);
      const ticmsmercaodira  = Number(valor);
      const valortotal = Number(tprod) + frete + funrural + icmsfrete + seguro + balnca + ticmsmercaodira + outros - Number(tdescontofruta);
      formRef.current?.setFieldValue('valortotal', valortotal);
    }
  };  
          
  const handlecalcularoutros = (valor: string | undefined) => {
    if (valor != null) {
      valor = valor.replace(/\D/g, '');
      valor = valor.replace(/(\d)(\d{2})$/, '$1.$2');
      valor = valor.replace(/(?=(\d{3})+(\D))\B/g, '');
      const data = formRef.current?.getData();
      const tprod= data?.valoritens;
      const icmsmercaodira =  Number(data?.icmsmercaodira);
      const tdescontofruta= data?.descontofrutas;
      const funrural = data?.tipofunrural=== '1' ? 0 : Number(data?.funrural);
      const frete = data?.tipofrete === '1' ? 0 : Number(data?.frete);
      const icmsfrete = data?.tipoicmsfrete === '1' ? 0 : Number(data?.icms);
      const seguro = data?.tiposeguro === '1' ? 0 : Number(data?.seguro);
      const balnca = data?.tipobalanca === '1' ? 0 : Number(data?.balanca);
      const toutros  = Number(valor);
      const valortotal = Number(tprod) + frete + funrural + icmsfrete + seguro + balnca + icmsmercaodira + toutros- Number(tdescontofruta);
      formRef.current?.setFieldValue('valortotal', valortotal);
    }
  };        
    
  const handleDiasVencimento = (dias: number | undefined) => {
    if (dias != null) {
      if (dias > 0){
        let date = new Date();
        date.setDate(date.getDate() + dias);
        formRef.current?.setFieldValue('vencimento', date);
      }   
    }
  };
  

  const handleFecharRomaneio = () => {
    formValidationSchema.
    validate(formRef.current?.getData(), { abortEarly: false })
    .then((dadosValidados) => {
      setIsLoading(true);
         RomaneioService
          .updateById(id, dadosValidados, rows, rowsids)
          .then((result) => {
            setIsLoading(false);
            if (result instanceof Error) {
              alert(result.message);
            } else {
              setOpenFechar(true);
            }
          });
    })
    .catch((errors: yup.ValidationError) => {
      const validationErrors: IVFormErrors = {};
      errors.inner.forEach(error => {
        if (!error.path) return;

        validationErrors[error.path] = error.message;
      });

      formRef.current?.setErrors(validationErrors);
    });  
  };

  const handleAbrirRomaneio = () => {
    setIsLoading(true);
    RomaneioService.AbrirRomaneio(id)
      .then((result) => {
        setIsLoading(false);
        if (result instanceof Error) {
          alert(result.message);
        } else {
          navigate('/romaneios');
        }
      });
  };
  
  const handlePrint = () => {
    RomaneioService.ImprimirRomaneio(id)
      .then((result) => {
        setIsLoading(false);
        if (result instanceof Error) {
          alert(result.message);
        } else { 
           PDFRomaneio(result);
        }
      });           
  };    
                                
  return (
    <LayoutBaseDePaginaNfe
       barraDeFerramentas={
        <FerramentasDeDetalheRomaneio
          textoBotaoNovo='Novo'
          mostrarBotaoSalvarEFechar
          mostrarBotaoSalvarCarregando={isLoading}
          mostrarBotaoSalvarEFecharCarregando={isLoading}
          mostrarBotaoNovo={id !== 'novo'}
          mostrarBotaoApagar={false}
          mostrarBotaoImpressao={id !== 'novo'}
          mostrarBotaoApagarCarregando={false}
          mostrarBotaoImpressaoCarregando={isLoading}
          mostrarBotaoFecharCarregando={isLoading}
          mostrarBotaoAbrirCarregando={isLoading}
          disableBotaoSalvar={statuss ? true: isLoading}
          disableBotaoSalvarEFechar={statuss ? true : isLoading }
          disableBotaoAbrir={statuss ? false : isLoading}
          mostrarBotaoAbrir={statuss ? true : isLoading}
          mostrarBotaoFechar={statuss ? isLoading : true}
          aoClicarEmFechar={handleFecharRomaneio}
          aoClicarEmAbrir={handleAbrirRomaneio}
          aoClicarEmSalvar={save}
          aoClicarEmSalvarEFechar={saveAndClose}
          aoClicarEmVoltar={() => navigate('/romaneios')}
          mostrarBotaoVoltarCarregando={isLoading}
          aoClicarEmNovo={() => navigate('/romaneios/detalhe/novo')}  
          aoClicarEmImpressao={() => handlePrint()}
          mostrarBotaoNovoCarregando={isLoading}  
        />              
      }     
    >      
    <VForm ref={formRef} onSubmit={handleSave}>
        <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
          <Grid container direction="column" padding={1} spacing={1}>
            {isLoading && (
              <Grid item>
                <LinearProgress variant='indeterminate' />
              </Grid>
            )}
            <Grid container item direction="row"  spacing={2}>  
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>  
                <Grid container item direction="row" spacing={1} >
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                    <AutoCompleteParceiro
                      isExternalLoading={statuss ? true : isLoading}
                      diasvencimento={handleDiasVencimento}
                    />  
                  </Grid>                  
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={2}>
                    <AutoCompleteProdutor
                      isExternalLoading={statuss ? true : isLoading}
                    />                 
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={2}>
                    <AutoCompleteFazenda  
                      isExternalLoading={statuss ? true : isLoading}
                    />     
                  </Grid>     
                  <Grid item xs={12} sm={12} md={2} lg={2} xl={1}>
                    <VTextField
                      fullWidth  
                      name='numeronfe'        
                      label='Numero NFE'
                      disabled={id === 'novo' ? isLoading : true}
                    />
                  </Grid>      

                  <Grid item xs={12} sm={12} md={2} lg={2} xl={1}>  
                    <VFieldMoney   
                      fullWidth  
                      name='valornfe'
                      label='Valor da NFE'
                      disabled={id === 'novo' ? isLoading : true}
                    />
                  </Grid>                 
                  <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                  <VTextField
                      fullWidth
                      name='numero'
                      label='Numero'           
                      disabled={true}
                    />     
                  </Grid>  
              </Grid>  
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                 <Grid container item direction="row" spacing={1} >
                  <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                  <VTextFieldData
                      fullWidth
                      name='dataemissao'  
                      label='Data Emissão'
                      disabled={true}
                    />
                  </Grid>                  
                  <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                     <VTextFieldData
                      fullWidth
                      name='vencimento'
                      label='Vencimento'
                      disabled={statuss ? true : isLoading}
                    />         
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                      {statuss ? (
                        <VTextFieldData
                        fullWidth
                        name='datafechado'
                        label='Data Acerto'
                        disabled={true}
                      />        
                      ):(
                        <VTextField
                        fullWidth
                        name='datafechado'
                        label='Data Acerto'
                        disabled={true}
                      />     
                    )}
                  </Grid>                 
                  <Grid item xs={12} sm={12} md={2} lg={2} xl={1}>
                     <BasicSelect
                          name='tipofrete'
                          label='Tipo Frete'
                          aoClicar={handletipofrete}
                          data={[
                            { nameBD: '1', descricao: '1-Pagar' },
                            { nameBD: '2', descricao: '2-Pago' },
                          ]}
                          disabled={statuss ? true : isLoading}
                    />                        
                  </Grid>     
                  <Grid item xs={12} sm={12} md={2} lg={2} xl={1}>
                  <VFieldMoney
                      fullWidth
                      name='frete'
                      label='Frete'
                      calcular={handlecalcularfrete}
                      disabled={statuss ? true : isLoading}
                    />
                  </Grid>     
                  <Grid item xs={12} sm={12} md={2} lg={2} xl={1}>
                  <BasicSelect
                          name='tipobalanca'
                          label='Tipo Balança'
                          aoClicar={handletipobalanca}
                          data={[
                            { nameBD: '1', descricao: '1-Pagar' },
                            { nameBD: '2', descricao: '2-Pago' },
                          ]}
                          disabled={statuss ? true : isLoading}
                    />          
                  </Grid> 
                  <Grid item xs={12} sm={12} md={2} lg={2} xl={1}>
                  <VFieldMoney
                      fullWidth
                      name='balanca'
                      label='Balança'
                      disabled={statuss ? true : isLoading}
                      calcular={handlecalcularbalanca}
                    />
                  </Grid>      
                  <Grid item xs={12} sm={12} md={2} lg={2} xl={1}>
                  <BasicSelect
                          name='tipoicmsfrete'
                          label='Tipo ICMS Frete'
                          aoClicar={handletipoicmsfrete}
                          data={[
                            { nameBD: '1', descricao: '1-Pagar' },
                            { nameBD: '2', descricao: '2-Pago' },
                          ]}
                          disabled={statuss ? true : isLoading}
                    />          
                  </Grid> 
                  <Grid item xs={12} sm={12} md={2} lg={2} xl={1}>
                  <VFieldMoney
                      fullWidth
                      name='icms'
                      label='ICMS Frete'
                      disabled={statuss ? true : isLoading}
                      calcular={handlecalcularicmsfrete}
                    />
                  </Grid>        
                <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                     <AutoCompleteTransportadora
                       isExternalLoading={statuss ? true : isLoading}
                       uuidtransportadora={handleuuidtransportadora}
                       />   
                     </Grid>                  
                     <Grid item xs={12} sm={12} md={4} lg={4} xl={2}>
                       <AutoCompleteVeiculos
                      isExternalLoading={statuss ? true : isLoading}
                      idtransportadora={transportadora}
                      />   
                     </Grid>    
                      
                  <Grid item xs={12} sm={12} md={2} lg={2} xl={1}>
                  <BasicSelect
                          name='tiposeguro'
                          label='Tipo Seguro'
                          aoClicar={handletiposeguro}
                          data={[
                            { nameBD: '1', descricao: '1-Pagar' },
                            { nameBD: '2', descricao: '2-Pago' },
                          ]}
                          disabled={statuss ? true : isLoading}
                    />          
                  </Grid> 
                  <Grid item xs={12} sm={12} md={2} lg={2} xl={1}>
                  <VFieldMoney
                      fullWidth
                      name='seguro'
                      label='Seguro'
                      disabled={statuss ? true : isLoading}
                      calcular={handlecalcularseguro}
                    />
                  </Grid>        
                  <Grid item xs={12} sm={12} md={2} lg={2} xl={1}>
                  <BasicSelect
                          name='tipofunrural'
                          label='Tipo FunRural'
                          aoClicar={handletipofunrural}
                          data={[
                            { nameBD: '1', descricao: '1-Pagar' },
                            { nameBD: '2', descricao: '2-Pago' },
                          ]}
                          disabled={statuss ? true : isLoading}
                    />          
                  </Grid> 
                  <Grid item xs={12} sm={12} md={2} lg={2} xl={1}>
                  <VFieldMoney
                      fullWidth
                      name='funrural'
                      label='FunRural'
                      disabled={statuss ? true : isLoading}
                      calcular={handlecalcularfunrural}
                    />
                  </Grid>        
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={1}>
                    <VFieldMoney
                      fullWidth
                      name='icmsmercaodira'
                      label='ICMS Mercadoria'
                      disabled={statuss ? true : isLoading}
                      calcular={handlecalcularicmsmercadoria}
                    />
                  </Grid> 

                  <Grid item xs={12} sm={12} md={6} lg={6} xl={1}>
                    <VFieldMoney
                      fullWidth
                      name='outros'
                      label='Outros (+)'
                      disabled={statuss ? true : isLoading}
                      calcular={handlecalcularoutros}
                    />
                  </Grid>                      
              </Grid>   
              </Grid>    
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TableContainer component={Paper} variant="outlined" sx={{width: 'auto', height:410, maxHeight:410 }}>
                  <Table size="small">
                    <TableHead  sx={{ backgroundColor: '#5E8A71' }}>
                      <TableRow >
                        <TableCell style={{ width: 20, maxWidth:10, color: '#fff' }} align='left'>Descição</TableCell>
                        <TableCell style={{ width: 20, color: '#fff' }} align='right'>Quantidade</TableCell>
                        <TableCell style={{ width: 10, color: '#fff' }} align='center'>UN</TableCell>
                        <TableCell style={{ width: 20, color: '#fff' }} align='center'>Valor Unitário</TableCell>
                        <TableCell style={{ width: 20, color: '#fff' }} align='right'>Valor Total</TableCell>
                        <TableCell style={{ width: 20, color: '#fff' }} align='right'>Qtd Quebra</TableCell>
                        <TableCell style={{ width: 10, color: '#fff' }} align='right'>Total Quebra</TableCell>
                        <TableCell style={{ width: 10, color: '#fff' }} align='right'>Total Liquido</TableCell>
                        <TableCell style={{ width: 2, maxWidth:'1px', maxHeight: '10px',  color: '#fff' }} align='center'>
                        <IconButton color='inherit' disabled={statuss ? true : isLoading}  aria-label="add to shopping cart" size="large">
                                <AddShoppingCartIcon  onClick={() => {setIditens('novo');  setOpen(true);}} />
                           </IconButton>
                        </TableCell>
                      </TableRow>
                    </TableHead>  
                    <TableBody>
                      { rows.map(row => (
                        <TableRow key={row.iditensrom} 
                          hover
                          sx={{ cursor: 'pointer' }}
                        >
                          <TableCell style={{ width: 340 }} >{row.descricao}</TableCell>
                          <TableCell style={{ width: 20 }} align='right'>{row.qtd.toLocaleString('pt-BR', { minimumFractionDigits: 2})}</TableCell>
                          <TableCell style={{ width: 10 }} align='center'>{row.un}</TableCell>
                          <TableCell style={{ width: 40 }} align='right'>{row.preco.toLocaleString('pt-BR', { minimumFractionDigits: 2})}</TableCell>
                          <TableCell style={{ width: 40 }} align='right'>{row.subtotal.toLocaleString('pt-BR', { minimumFractionDigits: 2})}</TableCell>
                          <TableCell style={{ width: 40 }} align='right'>{row.qtdajuste.toLocaleString('pt-BR', { minimumFractionDigits: 2})}</TableCell>
                          <TableCell style={{ width: 40 }} align='right'>{row.totaldesconto.toLocaleString('pt-BR', { minimumFractionDigits: 2})}</TableCell>
                          <TableCell style={{ width: 10 }} align='right'>{row.total.toLocaleString('pt-BR', { minimumFractionDigits: 2})}</TableCell>
                          <TableCell>
                             <IconButton size="small" onClick={() => {setIditens(row.iditensrom); handleBuscarDados(row.iditensrom); setOpen(true);}}>
                               <Icon >edit</Icon>
                              </IconButton>
                            <IconButton   disabled={statuss ? true : isLoading}  size="small" onClick={() => {handleDelete(row.iditensrom);}}>
                              <Icon>delete</Icon>
                             </IconButton>  
                          </TableCell>
                        </TableRow>
                      ))}   
                    </TableBody>
                    <TableFooter>
                      {isLoading && (
                        <TableRow>
                          <TableCell colSpan={3}>
                            <LinearProgress variant='indeterminate' />
                          </TableCell>
                        </TableRow>
                      )}
                    </TableFooter>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container item direction="row" spacing={2} >
                  <Grid item  xs={12} sm={12} md={6} lg={6} xl={8}>
                    <VTextField
                      fullWidth
                      name='obs'
                      label='Histórico'
                      multiline
                      rows={4}
                      disabled={statuss ? true : isLoading}
                    />
                  </Grid>         
                  <Grid item  xs={12} sm={12} md={6} lg={6} xl={4}>
                  <Grid container item direction="row" spacing={1} >
                  <Grid item  xs={12} sm={12} md={6} lg={6} xl={12}>
                      <AutoCompleteContaBancaria
                        name='idconta'
                        label='Conta Bancaria'
                        isExternalLoading={statuss ? true : isLoading}
                       />   
                     </Grid> 
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                    <VFieldMoney
                      fullWidth
                      name='valoritens'
                      label='Valor Itens'
                      disabled={true}
                    />
                  </Grid> 
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                    <VFieldMoney
                      fullWidth
                      name='descontofrutas' 
                      label='Desconto Frutas'
                      disabled={true}
                     />
                  </Grid> 
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                    <VFieldMoney
                      fullWidth
                      name='valortotal'
                      label='Valor Total'
                      disabled={true}
                    />
                  </Grid>                                             
                  </Grid>
                  </Grid>
              </Grid>
              </Grid>
          </Grid>
          <DialogItensRomaneios 
              id={iditens}
              value={guuid}
              dados={dadositens}
              open={open} 
              onDados={handleItens}
              onClose={handleClose}
              status={statuss}
            /> 
           <DialoFecharRomaneio 
              id={id}
              open={openfechar} 
              onClose={handleCloseFechar}
            /> 
          </Grid>
        </Box>
      </VForm>                                               

    </LayoutBaseDePaginaNfe>                      
  );
};                                          