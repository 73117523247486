/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { v4 as uuidv4 } from 'uuid';
import { Environment } from '../../../../environment';
import { ApiFinanceira } from '../../axiosfinanceiro';
import _ from 'lodash';

export interface IListagemRomaneios {
  idromaneio: string;
  dataemissao: Date;
  numero: number;
  parceiros: string;
  cpf: string;
  valortotal: number;
  status: number;
}

export interface IRomaneio {
  idparceiro: string;
  idprodutor: number;
  idfazenda: string;
  idconta: number;
  idtransportadora?: string | null;
  idveiculo?: string | null;
  dataemissao: Date;
  dataoperacao: Date;
  vencimento: Date;
  tipobalanca: number;
  tiposeguro: number;
  tipoicmsfrete: number;
  tipofunrural: number;
  tipofrete: number;
  frete: number;
  icms: number;
  balanca: number;
  icmsmercaodira: number;
  seguro: number;
  funrural: number;
  valoritens: number;
  valortotal: number;
  descontofrutas: number;
  numeronfe: string,
  valornfe: number;
  outros: number;
  obs: string;
  status: boolean;
  romaneionfe: boolean;
  datafechado: Date;  
  numero: number;
}

interface IItens{
  iditensrom: string;
  idfruta: string;
  idfazenda: string;
  descricao: string;
  qtd: number;
  preco: number;
  total: number;
  un: string;
  conversor: number;
  qtdajuste: number;
  subtotal: number;
  totaldesconto: number;
}

interface IGetRomaneios{
  idparceiro: string;
  idprodutor: number;
  idfazenda: string;
  idconta: number;
  idtransportadora?: string | null;
  idveiculo?: string | null;
  dataemissao: Date;
  dataoperacao: Date;
  vencimento: Date;
  tipobalanca: number;
  tiposeguro: number;
  tipoicmsfrete: number;
  tipofunrural: number;
  tipofrete: number;
  frete: number;
  icms: number;
  balanca: number;
  icmsmercaodira: number;
  seguro: number;
  funrural: number;
  valoritens: number;
  valortotal: number;
  descontofrutas: number;
  numeronfe: string,
  valornfe: number;
  outros: number;
  obs: string;
  status: boolean;
  romaneionfe: boolean;
  itens: IItens[];
}

interface IItensImpressao{
  iditensrom: string;
	descricao: string;
	qtd: number;
	un: string;
	preco: number;
	subtotal: number;
}

interface IImpressaoRomaneio{
  idromaneio: string;
	nome: string;
	cpf: string;
	rg: string;
	endereco: string;
	bairro: string;
	cep: string;
	cidade: string;
	puf: string;
	telefone: string;
	comprador: string;
	numeronfe: string;
	dataemissao: string;
	vencimento: string;
	status: string;
	tuf: string;
	placa: string;
	vuf: string;
  numero: number;
	transportadora: string;
	ttelefone: string;
	tcpf: string;
	tendereco: string;
  tcidade: string;
  trg: string;
  tipofrete: number; 
  frete: number; 
  tipoicmsfrete: number; 
  icms: number; 
  tipobalanca: number;
  balanca: number;
  tiposeguro: number;
  seguro: number;
  icmsmercaodira: number; 
  valoritens: number;
  tipofunrural: number;
  funrural: number;
  descontofrutas: number;
  desconto: number;
  outros: number;
  valortotal: number;
  obs: string;
  nomebanco: string;
  agencia: string;
  numeroconta: string;
  titular: string;  
  itens: IItensImpressao[];
}

interface IItensids{
  iditensrom: string;
}

type TotalCount = {
    data: IListagemRomaneios[];
    totalCount: number;
}

interface ErrorResponse {
  data: {
    errors: {
      [key: string]: string;
    };
  };
}

const getAll = async (page = 1, filter = '', tipo=''): Promise<TotalCount | Error> => {
  try {

    const urlRelativa = `/romaneios?page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&filter=${filter}&tipo=${tipo}`;

    const { data, headers } = await ApiFinanceira.get(urlRelativa);  
 
    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }
 
    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getById = async (id: string): Promise<IGetRomaneios | Error> => {
  try {

    const { data } = await ApiFinanceira.get(`/romaneios/${id}`);

    if (data) {
      return data; 
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const create = async (dados:  Omit<IRomaneio, 'numero' | 'datafechado'>, dadositens: IItens[]): Promise<string | Error> => {
  try {

    const guuid = uuidv4();

    const dadosExtraidos = dadositens.map(({ descricao, ...restoDoObjeto }) => ({ idromaneio: guuid, ...restoDoObjeto }));

    const dadosrom = {idromaneio: guuid, ...dados};

    let novoObj = _.omit(dadosrom, ['datafechado', 'numero']);

    console.log(novoObj);

    await ApiFinanceira.post<IItens>('/romaneios',  [novoObj, dadosExtraidos]);   
       
    return guuid;

  } catch (error) {
    if (typeof error === 'object' && error !== null && 'data' in error && 'errors' in (error as ErrorResponse).data) {
      const e = error as ErrorResponse;
      const errorMessages = Object.entries(e.data.errors)
        .map(([field, message]) => `${field}: ${message}`)
        .join('; ');
      return new Error(errorMessages);
    } else {
      return new Error('Erro ao Lancar Romaneio');
    }}
};

const updateById = async (id: string, dados: Omit<IRomaneio, 'numero' | 'datafechado'>, dadositens: IItens[], iddeletados: IItensids[]): Promise<void | Error> => {
  try {

    const dadosExtraidos = dadositens.map(({ descricao, ...restoDoObjeto }) => ({idromaneio: id, ...restoDoObjeto }));

    await ApiFinanceira.put(`/romaneios/${id}`, [dados, dadosExtraidos, iddeletados]);

  } catch (error) {
    if (typeof error === 'object' && error !== null && 'data' in error && 'errors' in (error as ErrorResponse).data) {
      const e = error as ErrorResponse;
      const errorMessages = Object.entries(e.data.errors)
        .map(([field, message]) => `${field}: ${message}`)
        .join('; ');
      return new Error(errorMessages);
    } else {
      return new Error('Erro ao Lancar Romaneio');
    }}
};

const FecharRomaneio = async (id: string, datafechamento: string): Promise<void | Error> => {
  try {

    await ApiFinanceira.put(`/fecharromaneio/${id}?datafechamento=${datafechamento}`);

  } catch (error) {
    if (typeof error === 'object' && error !== null && 'data' in error && 'errors' in (error as ErrorResponse).data) {
      const e = error as ErrorResponse;
      const errorMessages = Object.entries(e.data.errors)
        .map(([field, message]) => `${field}: ${message}`)
        .join('; ');
      return new Error(errorMessages);
    } else {
      return new Error('Erro ao Lancar Romaneio');
    }}
};

const AbrirRomaneio = async (id: string): Promise<void | Error> => {
  try {

    await ApiFinanceira.put(`/abrirromaneio/${id}`);

  } catch (error) {
    if (typeof error === 'object' && error !== null && 'data' in error && 'errors' in (error as ErrorResponse).data) {
      const e = error as ErrorResponse;
      const errorMessages = Object.entries(e.data.errors)
        .map(([field, message]) => `${field}: ${message}`)
        .join('; ');
      return new Error(errorMessages);
    } else {
      return new Error('Erro ao Lancar Romaneio');
    }}
};

const ImprimirRomaneio = async (id: string): Promise<IImpressaoRomaneio | Error> => {
  try {

    const { data } = await ApiFinanceira.get(`/imprimiromaneio/${id}`);

    if (data) {
      return data;
    }
    return new Error('Erro ao Imprimir o Romaneio.');

  } catch (error) {
    if (typeof error === 'object' && error !== null && 'data' in error && 'errors' in (error as ErrorResponse).data) {
      const e = error as ErrorResponse;
      const errorMessages = Object.entries(e.data.errors)
        .map(([field, message]) => `${field}: ${message}`)
        .join('; ');
      return new Error(errorMessages);
    } else {
      return new Error('Erro ao Lancar Romaneio');
    }}
};

export const RomaneioService = {
  getAll,
  create,
  getById,
  updateById,
  FecharRomaneio,
  AbrirRomaneio,
  ImprimirRomaneio,
};