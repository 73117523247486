import { useEffect, useMemo, useState } from 'react';
import { LinearProgress, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material';
import {  useSearchParams } from 'react-router-dom';
import { Environment } from '../../../shared/environment';
import { DashboardEstoqueService, IDashboardProdList } from '../../../shared/services/api/estoque/dashboard/DashboardEstoqueService';
import dayjs from 'dayjs';

export const ListagemDashboardSaida: React.FC<{ idprod: string, idLocalizacao: string  }> = ({ idprod, idLocalizacao }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [rows, setRows] = useState<IDashboardProdList[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);

  const pagina1 = useMemo(() => {
    return Number(searchParams.get('pagina1') || '1');
  }, [searchParams]);

  useEffect(() => {
    setIsLoading(true);
    if(idprod !== '' && idLocalizacao !== '' ){
      DashboardEstoqueService.DashboardProdListSaida(pagina1, idprod, idLocalizacao)
        .then((result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            alert(result.message);
          } else {
            setRows(result.data);
            setTotalCount(result.totalCount);
          }
        });
    }
  }, [pagina1, idprod, idLocalizacao]);
  
  return (
    <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto' }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{maxWidth: 60, width: 60, marginLeft:3 }} align='center'>Emissão</TableCell>
            <TableCell sx={{maxWidth: 80, width: 80, marginLeft:3 }} align='right'>Quantidade</TableCell>
            <TableCell>Origem Saida</TableCell>
            <TableCell>Descrição</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.iditens} hover sx={{ cursor: 'pointer' }}>
              <TableCell sx={{maxWidth: 60, width: 60, marginLeft:3 }}>{dayjs(row.dataemissao).format('DD/MM/YYYY')}</TableCell>
              <TableCell sx={{maxWidth: 80, width: 80, marginLeft:3 }} align='right'>{row.quantidade.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
              <TableCell>{row.origem}</TableCell>
              <TableCell>{row.descricao}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        {totalCount === 0 && !isLoading && (
          <caption>{Environment.LISTAGEM_VAZIA}</caption>
        )}
        <TableFooter>
          {isLoading && (
            <TableRow>
              <TableCell colSpan={4}>
                <LinearProgress variant='indeterminate' />
              </TableCell>
            </TableRow>
          )}
          {(totalCount > 0 && totalCount > 8) && (
            <TableRow>
              <TableCell colSpan={4}>
                <Pagination
                  page={pagina1}
                  count={Math.ceil(totalCount / 8)}
                  onChange={(_, newPage) => setSearchParams({ pagina1: newPage.toString() }, { replace: true })}
                />
              </TableCell>
            </TableRow>
          )}
        </TableFooter>
      </Table>
    </TableContainer>
  );
};