import { useEffect, useMemo, useState } from 'react';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';

import { FazendaService } from  '../../shared/services/api/cadastros/FazendaService';
import { useDebounce } from '../../shared/hooks';


type TAutoCompleteOption = {
  idfazenda: string;
  label: string;
}

interface IAutoCompleteFazendaProps {
  isExternalLoading?: boolean;
  uuidfazenda?: (novoTextoselect: string | undefined) => void;
  nome?: (novoTextoselect: string | undefined) => void;
}
export const AutoCompleteFazenda01: React.FC<IAutoCompleteFazendaProps> = ({ isExternalLoading = false, uuidfazenda, nome }) => {
  const { debounce } = useDebounce();

  const [selectedId, setSelectedId] = useState<string | undefined>('');

  const [opcoes, setOpcoes] = useState<TAutoCompleteOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [busca, setBusca] = useState('');


  useEffect(() => {  
    setIsLoading(true);
 
    debounce(() => {  
      FazendaService.getAlllist(selectedId, busca)
        .then((result) => {
          setIsLoading(false);

          if (result instanceof Error) {
            // alert(result.message);
          } else {
            setOpcoes(result.data.map(fazenda => ({ idfazenda: fazenda.idfazenda, label: fazenda.descricao })));
          }
        });
    });
  }, [busca, selectedId]);

  const autoCompleteSelectedOption = useMemo(() => {
    if (!selectedId) return null;

    const selectedOption = opcoes.find(opcao => opcao.idfazenda === selectedId);
    if (!selectedOption) return null;

    return selectedOption;
  }, [selectedId, opcoes]);


  return (
    <Autocomplete
      openText='Abrir'
      closeText='Fechar'
      noOptionsText='Sem opções'
      loadingText='Carregando...'

      disablePortal

      options={opcoes}
      loading={isLoading}
      disabled={isExternalLoading}
      value={autoCompleteSelectedOption}
      onInputChange={(_, newValue) => setBusca(newValue)}
      onChange={(_, newValue) => { setSelectedId(newValue?.idfazenda); nome?.(newValue?.label); uuidfazenda?.(newValue?.idfazenda); setBusca('');}}
      popupIcon={(isLoading) ? <CircularProgress size={28} /> : undefined}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Fazenda"
        />
      )}
    />
  );
};