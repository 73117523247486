/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
 
interface Item {
  iditensrom: string;
  qtd: number;
  descricao: string;
  fazenda: string;
  conversor: number;
  un: string;
  subtotal: number;
  total: number;
  qtdajuste: number;
  preco: number;
}

interface romaneio {
  idromaneio: string,
  dataemissao: Date
  vencimento: Date;
  numero: number;
  numeronfe: string;
  valortotal: number
  nome: string;
  cpf: string;
  valoritens: number;
  seguro: number;
  frete: number;
  icmsmercaodira: number; 
}

interface Entry {
  romaneios: romaneio;
  itens: Item[];
}


async function RRomaneioResumido(data: Entry[], datai: string, dataf: string){
  
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  const TotalGeral = data.reduce((acc, item) => acc + item.romaneios.valortotal, 0);
  const rows = data.map((row) => {
    return [
      {text: moment(row.romaneios.dataemissao).format('DD/MM/YYYY'), alignment: 'center',  fontSize: 8, margin: [0, 3, 0, 0]},
      {text: moment(row.romaneios.vencimento).format('DD/MM/YYYY'), alignment: 'center',  fontSize: 8, margin: [0, 3, 0, 0]},
      {text: moment(row.romaneios.vencimento).format('DD/MM/YYYY'), alignment: 'center',  fontSize: 8, margin: [0, 3, 0, 0]},
      {text: row.romaneios.numero, alignment: 'right',  fontSize: 8, margin: [0, 3, 0, 0]},
      {text: row.romaneios.numeronfe, alignment: 'right',  fontSize: 8, margin: [0, 3, 0, 0]},
      {text: row.romaneios.nome+ '\n CPF/CNPJ: '+row.romaneios.cpf, alignment: 'left',  fontSize: 8},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.romaneios.valortotal), alignment: 'right',  fontSize: 8, margin: [0, 3, 0, 0]},
    ]; 
  });  

  async function getBase64ImageFromUrl(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const img: HTMLImageElement = new Image();
      img.crossOrigin = 'Anonymous'; 
      img.onload = function () {
        const canvas: HTMLCanvasElement = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) {
          reject('Não foi possível obter o contexto 2D');
          return;
        }
        const image = this as HTMLImageElement;
        canvas.height = image.naturalHeight;
        canvas.width = image.naturalWidth;
        ctx.drawImage(image, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      };
      img.onerror = function () {
        reject(`Não foi possível carregar a imagem de ${url}`);
      };
      img.src = url;
    });
  }

  const imageUrl =  process.env.REACT_APP_URL_IMGLOG || ''; 
  const imageData = await getBase64ImageFromUrl(imageUrl);

  if (!imageData.startsWith('data:image')) {
    return new Error('A imagem não foi convertida corretamente para base64.');
  }
 
  const documentDefinition = {
 
    content: [
      {
        style: 'tableExample',
        table: {
          margin: [0, 0, 0, 0],
          widths: [48, 540],
          body: [
            [ 
              {
                image: imageData,
                width: 48,
                border: [false, false, false, false]
              },
              {
                border: [false, false, false, false],
                table: {
                  body: [
                    [ 
                      {text: process.env.REACT_APP_URL_EMPRESA, fontSize: 16,  margin: [0, 5, 0, 0], bold: true, color: process.env.COR_RELATORIOS_TEXTO,  alignment: 'center', fillColor: process.env.COR_RELATORIOS, border: [false, false, false, false]},
                    ],
                    [ 
                      {text: process.env.REACT_APP_URL_ENDERECO, fontSize: 10, border: [false, false, false, true]},
                    ],
                    [ 
                      {text: 'Relatório de Romaneios', margin: [5, 10, 0, 0], fontSize: 18, bold: true,  alignment: 'center',  border: [false, false, false, false]},
                    ],
                  ]
                }
              }
            ],
          ]
        }
      },
      {text: 'Período: '+datai+' a '+ dataf,  fontSize: 8},
      {
        table:{
          widths: [ 50, 50, 52, 30, 30, 200, 60],
          body: [
            [
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
            ],
            [
              {text: 'Data', alignment: 'center', fillColor: '#dddddd', fontSize: 8},
              {text: 'Data Fechado', alignment: 'center', fillColor: '#dddddd', fontSize: 8},
              {text: 'Vencimento', alignment: 'center', fillColor: '#dddddd', fontSize: 8},
              {text: 'Numero', alignment: 'center', fillColor: '#dddddd', fontSize: 8},
              {text: 'Nº NFE', alignment: 'left', fillColor: '#dddddd',  fontSize: 8},
              {text: 'Cliente', alignment: 'left', fillColor: '#dddddd',  fontSize: 8},
              {text: 'Valor Romaneio', alignment: 'right', fillColor: '#dddddd', fontSize: 8},
            ],
            ...rows,
            [
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              { text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(TotalGeral) , fontSize: 8, alignment: 'right'},
            ],
          ]

        },

      },
      
    ]
  };

  const pdfDocGenerator = pdfMake.createPdf(documentDefinition); 

  pdfDocGenerator.open();

}
export default RRomaneioResumido;

