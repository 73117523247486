/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Icon } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { AutoCompleteFazenda, AutoCompleteFrutas, IVFormErrors, VFieldMoney, VFieldMoney4, VForm, VTextField, useVForm } from '../../../shared/forms';
import * as yup from 'yup';

interface IFormData {
  iditensrom: string;
  idfruta: string;
  idfazenda: string;
  descricao: string;
  qtd: number;
  preco: number;
  total: number;
  un: string;
  conversor: number;
  qtdajuste: number;
  subtotal: number;
  totaldesconto: number;
}

export interface ConfirmationDialogRawProps {
  id: string;
  value: string;
  open: boolean;
  status: boolean;
  dados?: IFormData;
  onDados: (dados: IFormData) => void;
  onClose: (value?: string) => void;
}

const formValidationSchema: yup.ObjectSchema<Omit<IFormData, 'iditensrom' | 'descricao'>> = yup.object().shape({
  idfazenda: yup.string().required(),
  idfruta: yup.string().required(),
  qtd: yup.number().required(),
  preco: yup.number().required(),
  total: yup.number().required(),
  un: yup.string().required(),
  conversor: yup.number().required(),
  qtdajuste: yup.number().required(),
  subtotal: yup.number().required(),
  totaldesconto: yup.number().required(), 
});

export const DialogItensRomaneios: React.FC<ConfirmationDialogRawProps> = (props: ConfirmationDialogRawProps) => {
  
  const {formRef, save, } = useVForm();
  const { onClose, value: valueProp, open, ...other } = props;
  const [value, setValue] = useState(valueProp);
  const [isLoading, setIsLoading] = useState(false);  
  const [nomeprod, setNomeProd] = useState('');
  const [id, setId] = useState('');
 
  const guuid = uuidv4();

  const itensnovo  = {
    idfazenda: '',
    idfruta: '',
    qtd: 0,
    preco: 0, 
    total: 0, 
    un: 'kg',
    conversor: 1,
    qtdajuste: 0,
    subtotal: 0,
    totaldesconto: 0,
  };

  useEffect(() => {
    if(props.id === 'novo'){
      formRef.current?.setData(itensnovo);
    }else{
      if (props.dados && typeof props.dados === 'object') {
        setNomeProd(props.dados.descricao);
        formRef.current?.setData(props.dados); 
      }
    }
  }, [id, formRef]); 

  const handleProd = (descricao: string | undefined) => {
    if (descricao != null) {
      setNomeProd(descricao);
    }
  };
           
  const handleSave = (dados: IFormData) => {
    formValidationSchema.
      validate(dados, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);
        if(props.id === 'novo'){
          props.onDados({iditensrom: guuid, descricao: nomeprod, ...dadosValidados});
          onClose(value);
          setIsLoading(false);
        }else{
          props.onDados({iditensrom: props.id, descricao: nomeprod, ...dadosValidados});
          onClose(value);
          setIsLoading(false);
        }
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};     
        errors.inner.forEach(error => {
          if (!error.path) return;
          validationErrors[error.path] = error.message;
        });
        formRef.current?.setErrors(validationErrors);
      });
  };

  const handleCancel = () => {
    onClose();
  };

  useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }else{
      setId(valueProp);
    }
  }, [valueProp, open]);

  const handlecalcularQtd = (valor: string | undefined) => {
    if (valor != null) {
      valor = valor.replace(/\D/g, '');
      valor = valor.replace(/(\d)(\d{2})$/, '$1.$2');
      valor = valor.replace(/(?=(\d{3})+(\D))\B/g, '');
      const data = formRef.current?.getData();
      const preco = data?.preco;
      const desconto = data?.totaldesconto;
      const tvalor = (Number(valor) * preco);
      const total = tvalor - desconto;
      formRef.current?.setFieldValue('subtotal', tvalor);
      formRef.current?.setFieldValue('total', total);
    }
  };  

  const handlecalcularpreco = (valor: string | undefined) => {
    if (valor != null) {
      valor = valor.replace(/\D/g, '');
      valor = valor.replace(/(\d)(\d{4})$/, '$1.$2');
      valor = valor.replace(/(?=(\d{3})+(\D))\B/g, '');
      const data = formRef.current?.getData();
      const quantidade = data?.qtd;
      const qtdajuste = data?.qtdajuste;
      const tvalor = (Number(valor) * quantidade);
      const valordesconto = (Number(valor) * qtdajuste);
      const total = tvalor - valordesconto;
      formRef.current?.setFieldValue('totaldesconto', valordesconto);
      formRef.current?.setFieldValue('subtotal', tvalor);
      formRef.current?.setFieldValue('total', total);
    }
  };  

  const handlecalculardesconto = (valor: string | undefined) => {
    if (valor != null) {
      valor = valor.replace(/\D/g, '');
      valor = valor.replace(/(\d)(\d{2})$/, '$1.$2');
      valor = valor.replace(/(?=(\d{3})+(\D))\B/g, '');
      const data = formRef.current?.getData();
      const preco = data?.preco;
      const subtotal = data?.subtotal;
      const tvalor = (Number(valor) * preco);
      const total = subtotal - tvalor;
      formRef.current?.setFieldValue('totaldesconto', tvalor);
      formRef.current?.setFieldValue('total', total);
    }
  };  

  return (
    <Dialog
      open={open}
      {...other}
      sx={{ width: '100%'  }}
      maxWidth="sm"
    >
      <DialogTitle>Itens da Romaneios</DialogTitle>
      <DialogContent dividers>
        <VForm ref={formRef}  onSubmit={handleSave}>
          <Grid container direction="column" padding={1} >
            <Grid container item direction="row" padding={2} spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container item direction="row" spacing={1.5} >
                  <Grid item xs={10} sm={10} md={10} lg={8} xl={8}>
                    <AutoCompleteFrutas
                      isExternalLoading={props.status ? true : isLoading}
                      descricao={handleProd}
                    />  
                  </Grid>  
                  <Grid item xs={10} sm={10} md={10} lg={2} xl={2}>
                    <VTextField
                      fullWidth
                      name='un'
                      label='UN'
                      disabled={props.status ? true : isLoading}
                    />
                  </Grid>   
                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <VFieldMoney
                      fullWidth
                      name='conversor'
                      label='Conversor'
                      disabled={props.status ? true : isLoading}
                    />
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={12} xl={12}>
                    <AutoCompleteFazenda
                      isExternalLoading={props.status ? true : isLoading}
                    />    
                  </Grid>          
                 
                  <Grid item xs={2} sm={2} md={2} lg={4} xl={4}>
                    <VFieldMoney
                      fullWidth
                      name='qtd'
                      label='Quantidade'
                      calcular={handlecalcularQtd}
                      disabled={props.status ? true : isLoading}
                    />
                  </Grid>   
                  
                  <Grid item xs={2} sm={2} md={2} lg={4} xl={4}>
                    <VFieldMoney4
                      fullWidth
                      name='preco'
                      label='Valor Unitário'
                      calcular={handlecalcularpreco}
                      disabled={props.status ? true : isLoading}
                    />
                  </Grid>    
                  <Grid item xs={2} sm={2} md={2} lg={4} xl={4}>
                    <VFieldMoney4
                      fullWidth
                      name='subtotal'
                      label='Sub Total'
                      disabled={true}
                    />
                  </Grid>     
                  <Grid item xs={2} sm={2} md={2} lg={4} xl={4}>
                    <VFieldMoney
                      fullWidth
                      name='qtdajuste'
                      label='Quantidade Quebra'
                      calcular={handlecalculardesconto}
                      disabled={props.status ? true : isLoading}
                    />
                  </Grid>   
                  <Grid item xs={2} sm={2} md={2} lg={4} xl={4}>
                    <VFieldMoney
                      fullWidth
                      name='totaldesconto'
                      label='Total Desconto'
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={4} xl={4}>
                    <VFieldMoney4
                      fullWidth
                      name='total'
                      label='Valor Total'
                      disabled={true}
                    />
                  </Grid>  
                </Grid>
              </Grid>
            </Grid>
          </Grid> 
        </VForm>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' color='error' onClick={handleCancel}>Cancelar</Button>
        <Button variant='outlined' disabled={props.status ? true : isLoading} autoFocus onClick={save}>Confirmar</Button>
      </DialogActions>
    </Dialog>
    
  );
}; 