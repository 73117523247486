/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Icon } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { AutoCompleteProdutosNfe, BasicSelect, IVFormErrors, VFieldMoney, VFieldMoney4, VForm, VTextField, useVForm } from '../../../shared/forms';
import { AutoCompleteCfop } from '../../../shared/forms/AutoCompleteCfop';
import * as yup from 'yup';

interface IFormData {
  iditens: string;
  idprod: string;
  descricao: string;
  un: string;
  ncm: string;
  cfop: number;
  cst: number;
  quantidade: number;
  preco: number;
  desconto: number;
  total: number;
  frete: number;
  seguro: number;
  bcicms: number;
  percentual: number;
  valoricms: number;
  bcst: number;
  percentualst: number;
  valoricmsst: number;
  cstipi: number;
  bcipi: number;
  percentualipi: number;
  valoripi: number;
  cstpis: number;
  bcpis: number;
  percentualpis: number;
  valorpis: number;
  cstcofins: number;
  bccofins: number;
  percentualcofins: number;
  valorcofins: number;
  voutros: number;
  bcicmsst: number;
  preducaoicms: number;
}

export interface ConfirmationDialogRawProps {
  id: string;
  value: string;
  cfop: number;
  open: boolean;
  status: number;
  dados?: IFormData;
  onDados: (dados: IFormData) => void;
  onClose: (value?: string) => void;
}

const formValidationSchema: yup.ObjectSchema<Omit<IFormData, 'iditens' | 'descricao' | 'un' | 'ncm'>> = yup.object().shape({
  idprod: yup.string().required(),
  cfop: yup.number().required(),
  cst: yup.number().required(),
  quantidade: yup.number().required(),
  preco: yup.number().required(),
  desconto: yup.number().required(),
  total: yup.number().required(),
  frete: yup.number().optional().default(0),
  seguro: yup.number().optional().default(0),
  bcicms: yup.number().optional().default(0), 
  percentual: yup.number().optional().default(0),
  valoricms: yup.number().optional().default(0),
  bcst: yup.number().optional().default(0),
  percentualst: yup.number().optional().default(0),
  valoricmsst: yup.number().optional().default(0),
  cstipi: yup.number().optional().default(0),
  bcipi: yup.number().optional().default(0),
  percentualipi: yup.number().optional().default(0),
  valoripi: yup.number().optional().default(0),
  cstpis: yup.number().optional().default(0),
  bcpis: yup.number().optional().default(0),
  percentualpis: yup.number().optional().default(0),
  valorpis: yup.number().optional().default(0),
  cstcofins: yup.number().optional().default(0),
  bccofins: yup.number().optional().default(0),
  percentualcofins: yup.number().optional().default(0),
  valorcofins: yup.number().optional().default(0),
  voutros: yup.number().optional().default(0),
  bcicmsst: yup.number().optional().default(0),
  preducaoicms: yup.number().optional().default(0),
});

export const DialoNfe: React.FC<ConfirmationDialogRawProps> = (props: ConfirmationDialogRawProps) => {
  
  const {formRef, save, } = useVForm();
  const { onClose, value: valueProp, open, ...other } = props;
  const [value, setValue] = useState(valueProp);
  const [isLoading, setIsLoading] = useState(false);  
  const [isLoadingicms, setIsLoadingicms] = useState(true);  
  const [id, setId] = useState('');
  const [nomeprod, setNomeProd] = useState('');
  const [unprod, setUnProd] = useState('');
  const [ncmprod, setNcmProd] = useState('');
 
  const guuid = uuidv4();

  const itensnovo  = {
    idprod: '',
    cfop: props.cfop,
    cst: 4,
    quantidade: 0,
    preco: 0,
    desconto: 0,
    total: 0,
    frete: 0,
    bcicms: 0,
    percentual: 0,
    valoricms: 0,
    seguro: 0,
    bcst: 0,
    percentualst: 0,
    valoricmsst: 0,
    dcicmsst: 0,
    //cstipi: 0,
    bcipi: 0,
    percentualipi: 0,
    valoripi: 0,
    //cstpis: 0,
    bcpis: 0, 
    percentualpis: 0,
    valorpis: 0,
    //cstcofins: 0, 
    bccofins: 0,
    percentualcofins: 0,
    valorcofins: 0,
    voutros: 0,  
    bcicmsst: 0, 
    preducaoicms: 0,
  };

  useEffect(() => {
    if(props.id === 'novo'){
      formRef.current?.setData(itensnovo);
    }else{
      if (props.dados && typeof props.dados === 'object') {
        setNomeProd(props.dados.descricao);
        setNcmProd(props.dados.ncm);
        setUnProd(props.dados.un);

        formRef.current?.setData(props.dados); 
      }
    }
  }, [id, formRef]); 

 
           
  const handleSave = (dados: IFormData) => {
    formValidationSchema.
      validate(dados, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);
        if(props.id === 'novo'){
          props.onDados({iditens: guuid, descricao: nomeprod, un: unprod, ncm: ncmprod, ...dadosValidados});
          onClose(value);
          setIsLoading(false);
        }else{
          props.onDados({iditens: props.id, descricao: nomeprod, un: unprod, ncm: ncmprod, ...dadosValidados});
          onClose(value);
          setIsLoading(false);
        }
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};     
        errors.inner.forEach(error => {
          if (!error.path) return;
          validationErrors[error.path] = error.message;
        });
        formRef.current?.setErrors(validationErrors);
      });
  };

  const handleCancel = () => {
    onClose();
  };

  useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }else{
      setId(valueProp);
    }
  }, [valueProp, open]);

  const handleProd = (descricao: string | undefined) => {
    if (descricao != null) {
      setNomeProd(descricao);
    }
  };

  const handleun = (un: string | undefined) => {
    if (un != null) {
      setUnProd(un);
    } 
  };

  const handlencm = (ncm: string | undefined) => {
    if (ncm != null) {
      setNcmProd(ncm);
    }
  };

  const handlecalcularQtd = (valor: string | undefined) => {
    if (valor != null) {
      valor = valor.replace(/\D/g, '');
      valor = valor.replace(/(\d)(\d{2})$/, '$1.$2');
      valor = valor.replace(/(?=(\d{3})+(\D))\B/g, '');
      const data = formRef.current?.getData();
      const preco = data?.preco;
      const tvalor = (Number(valor) * preco);
      formRef.current?.setFieldValue('total', tvalor);
    }
  };  

  const handlebcimcs = (valor: string | undefined) => {
    if (valor != null) {
      valor = valor.replace(/\D/g, '');
      valor = valor.replace(/(\d)(\d{2})$/, '$1.$2');
      valor = valor.replace(/(?=(\d{3})+(\D))\B/g, '');
      const data = formRef.current?.getData();
      const percentual = data?.percentual;
      const tvalor = (Number(valor) * percentual / 100);
      formRef.current?.setFieldValue('valoricms', tvalor);
    }
  };  

  const handlepercentualicms = (valor: string | undefined) => {
    if (valor != null) {
      valor = valor.replace(/\D/g, '');
      valor = valor.replace(/(\d)(\d{2})$/, '$1.$2');
      valor = valor.replace(/(?=(\d{3})+(\D))\B/g, '');
      const data = formRef.current?.getData();
      const bcicms = data?.bcicms;
      const tvalor = (Number(valor) * bcicms / 100);
      formRef.current?.setFieldValue('valoricms', tvalor);
    }
  };  


  const handlecalcularPreco = (valor: string | undefined) => {
    if (valor != null) {
      valor = valor.replace(/\./g, '');
      valor = valor.replace(/,/g, '.');
      valor = valor.replace(/(\d)(\d{4})$/, '$1.$2');
      valor = valor.replace(/(?=(\d{3})+(\D))\B/g, ''); 
      const data = formRef.current?.getData();
      const quantidade = data?.quantidade;
      const tvalor = (Number(valor) * quantidade);
      formRef.current?.setFieldValue('total', tvalor);
    }
  };  

  const handlecst = (valor: string | undefined) => {
    if (valor != null) {
      if(valor === '3' || valor === '4' || valor === '5'  ){
        formRef.current?.setFieldValue('valoricms', 0); 
        formRef.current?.setFieldValue('percentual', 0); 
        formRef.current?.setFieldValue('bcicms', 0); 
        setIsLoadingicms(true);
      }else{
        if (isLoading){
          setIsLoadingicms(true);
        }else{
          setIsLoadingicms(false);
        }
      }  
    }
  };  

  return (
    <Dialog
      open={open}
      {...other}
      sx={{ width: '100%'  }}
      maxWidth="md"
    >
      <DialogTitle>Itens da Nota Fiscal</DialogTitle>
      <DialogContent dividers>
        <VForm ref={formRef}  onSubmit={handleSave}>
          <Grid container direction="column" padding={1} >
            <Grid container item direction="row" padding={1} spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container item direction="row" spacing={1} >
                  <Grid item xs={10} sm={10} md={10} lg={10} xl={12}>
                    <AutoCompleteProdutosNfe
                      descricao={handleProd}
                      un={handleun}
                      ncm={handlencm}
                      isExternalLoading={props.status === 1 ? isLoading : true}  />
                  </Grid>  
                  <Grid item xs={10} sm={10} md={10} lg={10} xl={6}>
                    <AutoCompleteCfop isExternalLoading={props.status === 1 ? isLoading : true}  />
                  </Grid>   
                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <VFieldMoney
                      fullWidth
                      name='quantidade'
                      label='Quantidade'
                      disabled={props.status === 1 ? isLoading : true}
                      calcular={handlecalcularQtd}
                    />
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <VFieldMoney
                      fullWidth
                      name='preco'
                      label='Preço Unitario'
                      disabled={props.status === 1 ? isLoading : true}
                      calcular={handlecalcularPreco}
                    />
                  </Grid>          
                 
                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <VFieldMoney4
                      fullWidth
                      name='total'
                      label='Valor Total'
                      disabled={true}
                    />
                  </Grid>   
                  <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                    <BasicSelect
                      name='cst'
                      label='Cst'
                      aoClicar={handlecst}
                      data={[
                        { nameBD: '0', descricao: '00' },
                        { nameBD: '1', descricao: '10' },
                        { nameBD: '2', descricao: '20' },
                        { nameBD: '3', descricao: '30' },
                        { nameBD: '4', descricao: '40' },
                        { nameBD: '5', descricao: '41' },
                        { nameBD: '6', descricao: '45' },
                        { nameBD: '7', descricao: '50' },
                        { nameBD: '8', descricao: '51' },
                        { nameBD: '9', descricao: '60' },
                        { nameBD: '10', descricao: '70' },
                        { nameBD: '13', descricao: '90' },
                        { nameBD: '24', descricao: '61' },
                      ]}
                      disabled={props.status === 1 ? isLoading : true}
                    />           
                  </Grid> 
                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <VFieldMoney
                      fullWidth
                      name='bcicms'
                      label='B.C ICMS'
                      calcular={handlebcimcs}
                      disabled={isLoadingicms}
                    />
                  </Grid>    
                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <VFieldMoney
                      fullWidth
                      name='percentual'
                      label='Percentual'
                      calcular={handlepercentualicms}
                      disabled={isLoadingicms}
                    />
                  </Grid>     
                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <VFieldMoney
                      fullWidth
                      name='preducaoicms'
                      label='P. Redução ICMS'
                      disabled={isLoadingicms}
                    />
                  </Grid>   
                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <VFieldMoney
                      fullWidth
                      name='valoricms'
                      label='Valor ICMS'
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <VFieldMoney
                      fullWidth
                      name='frete'
                      label='Frete'
                      disabled={props.status === 1 ? isLoading : true}
                    />
                  </Grid>  
                  
                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <BasicSelect
                      name='cstipi'
                      label='CST IPI'
                      data={[
                        { nameBD: '0', descricao: '00' },
                        { nameBD: '1', descricao: '49' },
                        { nameBD: '2', descricao: '50' },
                        { nameBD: '3', descricao: '99' },
                        { nameBD: '4', descricao: '01' },
                        { nameBD: '5', descricao: '02' },
                        { nameBD: '6', descricao: '03' },
                        { nameBD: '7', descricao: '04' },
                        { nameBD: '8', descricao: '05' },
                        { nameBD: '9', descricao: '51' },
                        { nameBD: '10', descricao: '52' },
                        { nameBD: '11', descricao: '53' },
                        { nameBD: '12', descricao: '54' },
                        { nameBD: '13', descricao: '55' },
                      ]}
                      disabled={props.status === 1 ? isLoading : true}
                    />        
                  </Grid>     
                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <VFieldMoney
                      fullWidth
                      name='bcipi'
                      label='B.C IPI'
                      disabled={props.status === 1 ? isLoading : true}
                    />
                  </Grid>    
                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <VFieldMoney
                      fullWidth
                      name='percentualipi'
                      label='Percentual IPI'
                      disabled={props.status === 1 ? isLoading : true}
                    />
                  </Grid>   
                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <VFieldMoney
                      fullWidth
                      name='valoripi'
                      label='Valor IPI'
                      disabled={props.status === 1 ? isLoading : true}
                    />
                  </Grid>    
                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <VFieldMoney
                      fullWidth
                      name='desconto'
                      label='Desconto'
                      disabled={props.status === 1 ? isLoading : true}
                    />
                  </Grid>    
                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <VFieldMoney
                      fullWidth
                      name='voutros'
                      label='Outros Valor'
                      disabled={props.status === 1 ? isLoading : true}
                    />
                  </Grid>  
                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <BasicSelect
                      name='cstpis'
                      label='CST PIS'
                      data={[
                        { nameBD: '0', descricao: '01' },
                        { nameBD: '1', descricao: '02' },
                        { nameBD: '2', descricao: '03' },
                        { nameBD: '3', descricao: '04' },
                        { nameBD: '4', descricao: '05' },
                        { nameBD: '5', descricao: '06' },
                        { nameBD: '6', descricao: '07' },
                        { nameBD: '7', descricao: '08' },
                        { nameBD: '8', descricao: '09' },
                        { nameBD: '9', descricao: '49' },
                        { nameBD: '10', descricao: '50' },
                        { nameBD: '11', descricao: '51' },
                        { nameBD: '12', descricao: '52' },
                        { nameBD: '13', descricao: '53' },
                        { nameBD: '14', descricao: '53' },
                        { nameBD: '15', descricao: '55' },
                        { nameBD: '16', descricao: '56' },
                        { nameBD: '17', descricao: '60' },
                        { nameBD: '18', descricao: '61' },
                        { nameBD: '19', descricao: '62' },
                        { nameBD: '20', descricao: '63' },
                        { nameBD: '21', descricao: '64' },
                        { nameBD: '22', descricao: '65' },
                        { nameBD: '23', descricao: '66' },
                        { nameBD: '24', descricao: '67' },
                        { nameBD: '25', descricao: '70' },
                        { nameBD: '26', descricao: '71' },
                        { nameBD: '27', descricao: '72' },
                        { nameBD: '28', descricao: '73' },
                        { nameBD: '29', descricao: '74' },
                        { nameBD: '30', descricao: '75' },
                        { nameBD: '31', descricao: '98' },
                        { nameBD: '31', descricao: '99' },
                      ]}
                      disabled={props.status === 1 ? isLoading : true}
                    />        
                  </Grid>     

                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <VFieldMoney
                      fullWidth
                      name='bcpis'
                      label='B.C PIS'
                      disabled={props.status === 1 ? isLoading : true}
                    />
                  </Grid>     

                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <VFieldMoney
                      fullWidth
                      name='percentualpis'
                      label='Percentual PIS'
                      disabled={props.status === 1 ? isLoading : true}
                    />
                  </Grid>     

                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <VFieldMoney
                      fullWidth
                      name='valorpis'
                      label='Valor PIS'
                      disabled={props.status === 1 ? isLoading : true}
                    />
                  </Grid>  
                   
                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <BasicSelect
                      name='cstcofins'
                      label='CST CONFIS'
                      data={[
                        { nameBD: '0', descricao: '01' },
                        { nameBD: '1', descricao: '02' },
                        { nameBD: '2', descricao: '03' },
                        { nameBD: '3', descricao: '04' },
                        { nameBD: '4', descricao: '05' },
                        { nameBD: '5', descricao: '06' },
                        { nameBD: '6', descricao: '07' },
                        { nameBD: '7', descricao: '08' },
                        { nameBD: '8', descricao: '09' },
                        { nameBD: '9', descricao: '49' },
                        { nameBD: '10', descricao: '50' },
                        { nameBD: '11', descricao: '51' },
                        { nameBD: '12', descricao: '52' },
                        { nameBD: '13', descricao: '53' },
                        { nameBD: '14', descricao: '53' },
                        { nameBD: '15', descricao: '55' },
                        { nameBD: '16', descricao: '56' },
                        { nameBD: '17', descricao: '60' },
                        { nameBD: '18', descricao: '61' },
                        { nameBD: '19', descricao: '62' },
                        { nameBD: '20', descricao: '63' },
                        { nameBD: '21', descricao: '64' },
                        { nameBD: '22', descricao: '65' },
                        { nameBD: '23', descricao: '66' },
                        { nameBD: '24', descricao: '67' },
                        { nameBD: '25', descricao: '70' },
                        { nameBD: '26', descricao: '71' },
                        { nameBD: '27', descricao: '72' },
                        { nameBD: '28', descricao: '73' },
                        { nameBD: '29', descricao: '74' },
                        { nameBD: '30', descricao: '75' },
                        { nameBD: '31', descricao: '98' },
                        { nameBD: '31', descricao: '99' },
                      ]}
                      disabled={props.status === 1 ? isLoading : true}
                    />        
                  </Grid>     
                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <VFieldMoney
                      fullWidth
                      name='bccofins'
                      label='B.C CONFIS'
                      disabled={props.status === 1 ? isLoading : true}
                    />
                  </Grid>     

                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <VFieldMoney
                      fullWidth
                      name='percentualcofins'
                      label='Percentual CONFIS'
                      disabled={props.status === 1 ? isLoading : true}
                    />
                  </Grid>     

                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <VFieldMoney
                      fullWidth
                      name='valorcofins'
                      label='Valor CONFIS'
                      disabled={props.status === 1 ? isLoading : true}
                    />
                  </Grid>        
                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <VFieldMoney
                      fullWidth
                      name='bcicmsst'
                      label='B.C ICMS ST'
                      disabled={props.status === 1 ? isLoading : true}
                    />
                  </Grid>                      
                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <VFieldMoney
                      fullWidth
                      name='percentualst'
                      label='Percentual ST'
                      disabled={props.status === 1 ? isLoading : true}
                    />
                  </Grid>     
                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <VFieldMoney
                      fullWidth
                      name='valoricmsst'
                      label='Valor ICMS ST'
                      disabled={props.status === 1 ? isLoading : true}
                    />
                  </Grid>      
                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <VFieldMoney
                      fullWidth
                      name='seguro'
                      label='Seguro'
                      disabled={props.status === 1 ? isLoading : true}
                    />
                  </Grid>                 
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </VForm>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' color='error' onClick={handleCancel}>Cancelar</Button>
        <Button variant='outlined' disabled={props.status === 1 ? isLoading : true} autoFocus onClick={save}>Confirmar</Button>
      </DialogActions>
    </Dialog>
    
  );
}; 