import {  useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import * as yup from 'yup';
import { FeriasService } from '../../../shared/services/api/rh/ferias/FeriasService';

export interface ConfirmationDialogRawProps {
  id: string;
  idfun: string;
  value: string;
  open: boolean;
  onClose: (value?: string) => void;
}

const FeriasSchema = yup.object().shape({
  datainicial: yup.date().required().test('valid-date', 'Selecione uma data válida', function (value) {
    const currentDate = new Date();
    if (!value || (value.getMonth() < currentDate.getMonth() && value.getFullYear() <= currentDate.getFullYear())) {
      return false;
    }
    return true;
  }),
  datafinal: yup.date().required().test('valid-date', 'Selecione uma data válida', function (value) {
    const currentDate = new Date();
    if (!value || (value.getMonth() < currentDate.getMonth() && value.getFullYear() <= currentDate.getFullYear())) {
      return false;
    }
    return true;
  })
}); 

export const DialogFeriasAdd: React.FC<ConfirmationDialogRawProps> = (props: ConfirmationDialogRawProps) => {
 
  const { onClose, open, ...other } = props;
  const [datainicial, setDataInicial] = useState<Dayjs | null>(dayjs()); 
  const [datafinal, setDataFinal] = useState<Dayjs | null>(dayjs()); 
  const [isLoading, setIsLoading] = useState(false);
  const [inicialError, setInicialError] = useState('');
  const [finalError, setFinalError] = useState('');
 
  const handleCancel = () => {
    onClose();
  };

  const handleSubmit = () => {
    FeriasSchema.
      validate({ datainicial, datafinal }, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);
        FeriasService.create(props.idfun, dadosValidados.datainicial, dadosValidados.datafinal)
          .then((result) => {
            setIsLoading(false);
            if (result instanceof Error) {
              alert(result.message);
            } else {
              onClose();
            }
          });
      })
      .catch((errors: yup.ValidationError) => {
        setIsLoading(false);
        errors.inner.forEach(error => {
          if (error.path === 'datainicial') {
            setInicialError(error.message);
          } else if (error.path === 'datafinal') {
            setFinalError(error.message);
          }
        });
      });
  };
  return (
    <Dialog
      open={open}
      {...other}      
    >
      <DialogTitle>Adicionar Férias</DialogTitle>
      <DialogContent dividers>
        <Box display="flex" padding={3} gap={2} component={Paper} variant="outlined">
          <LocalizationProvider  dateAdapter={AdapterDayjs}>
            <DatePicker
              format='DD/MM/YYYY'
              value={datainicial}
              disabled={isLoading}
              label='Data Saida'
              onChange={(newValue) => setDataInicial(newValue)} 
              slotProps={{
                textField: {    
                  error:!!inicialError,
                  helperText:inicialError,
                }
              }}
            />
          </LocalizationProvider>
          <LocalizationProvider  dateAdapter={AdapterDayjs}>
            <DatePicker 
              format='DD/MM/YYYY'
              value={datafinal}
              disabled={isLoading}
              label='Data Retorno'
              onChange={(newValue) => setDataFinal(newValue)} 
              slotProps={{
                textField: {    
                  error:!!finalError,
                  helperText:finalError,
                }
              }}
            />
          </LocalizationProvider>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button sx={{ minWidth:'30%'  }} variant='outlined' onClick={handleCancel}>Voltar</Button>      
        <Button sx={{ minWidth:'30%'  }} variant='contained' onClick={handleSubmit}>Adicionar</Button>     
      </DialogActions>
    </Dialog>
  );
}; 