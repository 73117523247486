/* eslint-disable @typescript-eslint/no-unused-vars */
import { Dayjs } from 'dayjs';
import { Environment } from '../../../../environment';
import { Apiestoque } from '../../axiosestoque';
import { v4 as uuidv4 } from 'uuid';

export interface IListagemSaidas {
  idsaida: string;
  dataoperacao: Date;

  fazenda: string;
  total: number;
  operacoes: string;
}

export interface IitensSaidaDialog{
  iditens: string;
  idprod: string;
  idlocalizacao: string; 
  qtdsaida: number; 
  validade?: string | null;
  qtdentrada: number;
  preco: number;
  un: string;
  conversor: number;
  total: number;

  referencia: string;
  qtdestoque: number;
  filtro: string;
  descricao: string;
  localizacao: string;
  reqvencimento: boolean; 
}

interface IgetSaida {
  idfazenda: string;
  idoperacoes: string;
  idparceiro: string;
  idcultivo: string;
  idpivot: string;
  datalan: Date;
  dataoperacao: Date;
  total: number;
  obs: string;

  venda: boolean;
  itens: IitensSaidaDialog[];
}

interface IDetalheSaida {
  idfazenda?: string | null;
  idoperacoes: string;
  idparceiro?: string | null;
  idcultivo?: string | null;
  idpivot?: string | null;
  datalan: Date;
  dataoperacao: Date;
  total: number;
  obs: string;

  venda: boolean;
}

interface IItensids{
  iditens: string;
}

type TotalCount = {
  data: IListagemSaidas[];
  totalCount: number;
}

interface ErrorResponse {
  data: {
    errors: {
      [key: string]: string;
    };
  };
}

interface EstoqueQuantidade {
  idprod: string; 
  idlocalizacao: string; 
  vencimento: boolean; 
  validade: string | Dayjs | null | undefined;
}

interface EstoqueQuantidadeFaz {
  idprod: string; 
  vencimento: boolean; 
  validade: string | Dayjs | null | undefined;
}

interface Quantidade{
  quantidade: number;
}

const getAll = async (page = 1, dataInicial = '', dataFinal='', tipo='', idfazenda=''): Promise<TotalCount | Error> => {
  try {
    
    const urlRelativa = `/saidaestoque?page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&tipo=${tipo}&inicial=${dataInicial}&final=${dataFinal}&fazenda=${idfazenda}`;
    const { data, headers } = await Apiestoque.get(urlRelativa);  
 
    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }
 
    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getById = async (id: string): Promise<IgetSaida | Error> => {
  try {

    const { data } = await Apiestoque.get(`/saidaestoque/${id}`);

    if (data) {
      return data;
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const create = async (dados: IDetalheSaida, dadositens: IitensSaidaDialog[]): Promise<string | Error> => {
  
  try {
    const guuid = uuidv4();
    const dadosExtraidos = dadositens.map(({ qtdestoque, referencia, descricao, localizacao, reqvencimento, filtro, ...restoDoObjeto }) => ({ idoperacao: guuid, ...restoDoObjeto }));
    const { venda, ...dadosIDetalheSaida } = dados;
    const dadosSaida = {idsaida: guuid, ...dadosIDetalheSaida};
    
    const { data } = await Apiestoque.post<IDetalheSaida>('/saidaestoque',  [dadosSaida, dadosExtraidos]);

    if (Object.keys(data).length === 0) {
      return guuid;
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    if (typeof error === 'object' && error !== null && 'data' in error && 'errors' in (error as ErrorResponse).data) {
      const e = error as ErrorResponse;
      const errorMessages = Object.entries(e.data.errors)
        .map(([field, message]) => `${field}: ${message}`)
        .join('; ');
      return new Error(errorMessages);
    } else {
      return new Error('Erro ao Lançar Entrada');
    }
  }
};

const estoqueQuantidade = async (dados: EstoqueQuantidade): Promise<Quantidade | Error> => {
  
  try {
    
    const { data } = await Apiestoque.post('/estoquequantidade', dados);  

    if (data) {
      return (data);
    }

    return new Error('Erro ao pesquisar quantidade do ítem selecionado.');
  } catch (error) {
    if (typeof error === 'object' && error !== null && 'data' in error && 'errors' in (error as ErrorResponse).data) {
      const e = error as ErrorResponse;
      const errorMessages = Object.entries(e.data.errors)
        .map(([field, message]) => `${field}: ${message}`)
        .join('; ');
      return new Error(errorMessages);
    } else {
      return new Error('Erro pesquisar quantidade do ítem.');
    }
  }
};

const estoqueQuantidadeFaz = async (dados: EstoqueQuantidadeFaz): Promise<Quantidade | Error> => {
  
  try {
    
    const { data } = await Apiestoque.post('/estoquequantidadefaz', dados);  

    if (data) {
      return (data);
    }

    return new Error('Erro ao pesquisar quantidade do ítem selecionado.');
  } catch (error) {
    if (typeof error === 'object' && error !== null && 'data' in error && 'errors' in (error as ErrorResponse).data) {
      const e = error as ErrorResponse;
      const errorMessages = Object.entries(e.data.errors)
        .map(([field, message]) => `${field}: ${message}`)
        .join('; ');
      return new Error(errorMessages);
    } else {
      return new Error('Erro pesquisar quantidade do ítem.');
    }
  }
};

const updateById = async (id: string, dados: IDetalheSaida, dadositens: IitensSaidaDialog[], iddeletados: IItensids[]): Promise<void | Error> => {
  try {

    const dadosExtraidos = dadositens.map(({qtdestoque, descricao, localizacao, reqvencimento, filtro, referencia, ...restoDoObjeto }) => ({ idoperacao: id, ...restoDoObjeto }));
    const { venda, ...dadosIDetalheSaida } = dados;
    const dadosSaida = {idsaida: id, ...dadosIDetalheSaida};

    await Apiestoque.put(`/saidaestoque/${id}`, [dadosSaida, dadosExtraidos, iddeletados]);

  } catch (error) {

    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
    
  }
};

const deleteById = async (id: string): Promise<void | Error> => {
  try {
    await Apiestoque.delete(`/saidaestoque/${id}`);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
  }
};

export const SaidaService = {
  getAll,
  create,
  getById,
  updateById,
  deleteById,
  estoqueQuantidade,
  estoqueQuantidadeFaz,
};