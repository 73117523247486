import { useEffect, useMemo, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Icon, IconButton, LinearProgress, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow} from '@mui/material';
import { Environment } from '../../../shared/environment';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import { FeriasService, IListagemFerias } from '../../../shared/services/api/rh/ferias/FeriasService';

export interface ConfirmationDialogRawProps {
  id: string;
  value: string;
  open: boolean;
  onClose: (value?: string) => void;
}

export const DialogFerias: React.FC<ConfirmationDialogRawProps> = (props: ConfirmationDialogRawProps) => {
 
  const { onClose, open, ...other } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [rows, setRows] = useState<IListagemFerias[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);
  const idfun = useMemo(() => {
    return searchParams.get('idfun') || props.id;
  }, [searchParams]);
 
  useEffect(() => {
    if(idfun != 'novo'){
      setIsLoading(true);
      FeriasService.getAll(pagina, props.id)
        .then((result) => {
          setIsLoading(false);
  
          if (result instanceof Error) {
            alert(result.message);
          } else {
            setTotalCount(result.totalCount);
            setRows(result.data);
          }
        });
    }
  }, [pagina, idfun, open]);

  const handleCancel = () => {
    onClose();
  };

  const handleDelete = (ids: string) => {
    if (confirm('Realmente deseja apagar?')) {
      FeriasService.deleteById(ids)
        .then(result => {
          if (result instanceof Error) {
            alert(result.message);
          } else {
            alert('Registro apagado com sucesso!');
            setIsLoading(true);
            FeriasService.getAll(pagina, idfun)
              .then((result) => {
                setIsLoading(false);
                if (result instanceof Error) {
                  alert(result.message);
                } else {
                  setTotalCount(result.totalCount);
                  setRows(result.data);
                }
              });
          }
        });
    } 
  };

  return (
    <Dialog
      open={open}
      {...other}      
    >
      <DialogTitle>Histórico de Férias</DialogTitle>
      <DialogContent dividers>
        <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto', minWidth: 350, height: 387}}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Data Saida</TableCell>
                <TableCell>Data Retorno</TableCell>
                <TableCell>Periodo</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <TableRow key={row.idferias}>
                  <TableCell>{moment(row.datainicial).format('DD/MM/YYYY')}</TableCell>
                  <TableCell>{moment(row.datafinal).format('DD/MM/YYYY')}</TableCell>
                  <TableCell>{row.periodo}</TableCell>
                  <TableCell>
                    <IconButton   disabled={row.status ? true : isLoading} color='error'  size="small" onClick={() => {handleDelete(row.idferias);}}>
                      <Icon>delete</Icon>
                    </IconButton>  
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
  
            {totalCount === 0 && !isLoading && (
              <caption>{Environment.LISTAGEM_VAZIA}</caption>
            )}
            <TableFooter>
              {isLoading && (
                <TableRow>
                  <TableCell colSpan={3}>
                    <LinearProgress variant='indeterminate' />
                  </TableCell>
                </TableRow>
              )}
              {(totalCount > 0 && totalCount > 5) && (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Pagination
                      page={pagina}
                      count={Math.ceil(totalCount / 5)}
                      onChange={(_, newPage) => setSearchParams({ idfun, pagina: newPage.toString() }, { replace: true })}
                    />
                  </TableCell>
                </TableRow>
              )}
            </TableFooter>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button sx={{ minWidth:'30%'  }} variant='outlined' onClick={handleCancel}>Voltar</Button>      
      </DialogActions>
    </Dialog>
    
  );
}; 