/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useState } from 'react';
import { IconButton, LinearProgress, Pagination, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material';
import {  useSearchParams } from 'react-router-dom';

import { Environment } from '../../../shared/environment';
import dayjs, { Dayjs } from 'dayjs';
import { FerramentasDaListagemFinanceiroConciliacao } from '../../../shared/components';
import { CaixaConstasService, IListagem, IResultado } from '../../../shared/services/api/Financeiro/Caixa';
import { LayoutBaseDeFinanceiroConciliacao } from '../../../shared/layouts';
import ReplyIcon from '@mui/icons-material/Reply';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import RepeatIcon from '@mui/icons-material/Repeat';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import PDFCaixa from './Impressaocaixa';
import { DialoFecharCaixa } from './DialogFecharCaixa';
import { DialogAbrirCaixa } from './DialogAbrirCaixa';
import EngineeringIcon from '@mui/icons-material/Engineering';


export const Conciliacao: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [rows, setRows] = useState<IListagem[]>([]);
  const [resultados, setResultados] = useState<IResultado | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [openFechar, setOpenFechar] = useState(false);  
  const [openAbrir, setOpenAbrir] = useState(false);  
  const [dataInicial, setDataInicial] = useState(''); 
  const [dataFinal, setDataFinal] = useState(''); 
  const [status, setStatus] = useState(false);
  const [conta, setConta] = useState('');

         
  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);  
  
  const contaselect = useMemo(() => {
    return searchParams.get('contaselect') || 0; 
  }, [searchParams]);

  const descricaoconta = useMemo(() => {
    return searchParams.get('descricaoconta') || ''; 
  }, [searchParams]);
  

  useEffect(() => {
    if(contaselect != 0){
      setConta(descricaoconta);
      setIsLoading(true);  
      CaixaConstasService.getAll(pagina,  '', contaselect, dataInicial, dataFinal)
        .then((result) => {
          setIsLoading(false);  
          if (result instanceof Error) {
            alert(result.message);
          } else { 
            setTotalCount(result.totalCount);
            setRows(result.lan);
            setStatus(result.lan[0].status);
            if (typeof result.resultado === 'object') 
              setResultados(result.resultado);
          }
        });
    }
  }, [pagina]);    
 
          
  const handleClose = (newValue?: string) => {
    setOpenAbrir(false); 
    setOpenFechar(false);  
    if (newValue === 'true') {
      if(contaselect != 0){
        setConta(descricaoconta);
        setIsLoading(true);  
        CaixaConstasService.getAll(pagina, '', contaselect, dataInicial, dataFinal)
          .then((result) => {
            setIsLoading(false);  
            if (result instanceof Error) {
              alert(result.message);
            } else { 
              setTotalCount(result.totalCount);
              setRows(result.lan);
              setStatus(result.lan[0].status);
              if (typeof result.resultado === 'object') 
                setResultados(result.resultado);
            }
          });
      }
    }
  };  

  const handlePesquisa = (datainicial: Dayjs | null, datafinal: Dayjs | null) => {
    const formattedDateInicial = datainicial ? dayjs(datainicial).format('DD/MM/YYYY') : '';
    const formattedDateFinal = datafinal ? dayjs(datafinal).format('DD/MM/YYYY') : '';
    setDataInicial(formattedDateInicial);
    setDataFinal(formattedDateFinal);
    setIsLoading(true);    
    if(contaselect != 0){ 
      setConta(descricaoconta);
      CaixaConstasService.getAll(1, '', contaselect, formattedDateInicial, formattedDateFinal)
        .then((result) => {
          setIsLoading(false);  
          if (result instanceof Error) {
            alert(result.message);
          } else { 
            setTotalCount(result.totalCount);
            if(result.lan.length > 0){
              setRows(result.lan);
              setStatus(result.lan[0].status);  
            }else{
              setRows([]);
              setStatus(false);  
            }          
            if (typeof result.resultado === 'object') 
              setResultados(result.resultado);
          }
        });
    }     
  };        
   
  const handlePrint = () => {
    setIsLoading(true);    
    if(contaselect != 0){ 
      setConta(descricaoconta);
      CaixaConstasService.getAll(1, 'impressao', contaselect, dataInicial, dataFinal)
        .then((result) => {
          setIsLoading(false);  
          if (result instanceof Error) {
            alert(result.message);
          } else { 
            if(result.lan.length > 0){
              if (typeof result.resultado === 'object') 
                PDFCaixa(result.lan, result.resultado, conta);
            }
          }
        });
    }     
  };    
  const handleFechar = () => {
    setOpenFechar(true);
  };

  const handleAbrir = () => {
    setOpenAbrir(true);
  };

  const handleVoltar = (idoperacao: string, datacaixa: Date, idconta: number, tipos: number) => {
    const voltarlancamento = {
      idoperacao: idoperacao,
      datacaixa: datacaixa,
      idconta: idconta,
      tipos: tipos,
    };
    if (confirm('Realmente deseja Voltar Este Lançamento?')) {
      CaixaConstasService
        .VoltarLancamento(voltarlancamento)
        .then((result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            alert(result.message);
          }else{
            setIsLoading(true);    
            if(contaselect != 0){ 
              setConta(descricaoconta);
              CaixaConstasService.getAll(1, '', contaselect, dataInicial, dataFinal)
                .then((result) => {
                  setIsLoading(false);  
                  if (result instanceof Error) {
                    alert(result.message);
                  } else { 
                    setTotalCount(result.totalCount);
                    if(result.lan.length > 0){
                      setRows(result.lan);
                      setStatus(result.lan[0].status);  
                    }else{
                      setRows([]);
                      setStatus(false);  
                    }          
                    if (typeof result.resultado === 'object') 
                      setResultados(result.resultado);
                  }
                });
            }     
          }
        });
    }
  };  
    
  return (
    <LayoutBaseDeFinanceiroConciliacao
      titulo='Conciliação'
      saldoanterior={resultados?.saldoanterior ? resultados.saldoanterior.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0,00'}
      credito={resultados?.credito ? resultados.credito.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0,00'}
      debito={resultados?.debito ? resultados.debito.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0,00'}
      saldo={resultados?.saldo ? resultados.saldo.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0,00'}
      cheque={resultados?.valorcheque ? resultados.valorcheque.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0,00'}
      saldoreal={resultados?.saldoreal ? resultados.saldoreal.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0,00'}
      barraDeFerramentas={
        <FerramentasDaListagemFinanceiroConciliacao
          mostrarBotaoAbrirConta={status ? true : false}
          mostrarBotaoFecharConta={status ? false : true}
          aoClicarEmFecharConta={handleFechar}
          aoClicarEmImpressao={handlePrint}
          aoClicarEmAbrirConta={handleAbrir}
          aoClicarEmPesquisa={handlePesquisa}
          aoMudarTextoDoIdconta={(texto, texto1) => setSearchParams({contaselect: texto.toString(),  descricaoconta: texto1,  pagina: '1' }, { replace: true })}
        />
      }
    >
      <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto', marginRight: 1 }}>
        <Table> 
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ maxWidth: 10, width:10 }}></TableCell>
                <TableCell sx={{ maxWidth: 100, width:100 }}>Data</TableCell>
                <TableCell align='center'>Numero</TableCell>
                <TableCell align='left'>Parceiro</TableCell>
                <TableCell align='left'>Histórico</TableCell>
                <TableCell align='right'>Crédito</TableCell>
                <TableCell align='right'>Débito</TableCell>
                <TableCell sx={{ maxWidth: 15, width:15 }} align='center'></TableCell>
              </TableRow>
            </TableHead>    
            <TableBody>
              {rows.map(row => (
                <TableRow key={row.idcaixa} 
                  hover
                  sx={{ cursor: 'pointer' }}  
                >
                  <TableCell>
                    {(() => {
                      switch (row.tipos) {
                      case 1:
                        return <RequestPageIcon color='primary'/>;
                      case 2:
                        return <RequestPageIcon color='error'/>;
                      case 3:
                        return <CreditScoreIcon color='primary'/>;
                      case 4:
                        return <CreditScoreIcon color='error' />;
                      case 5:
                        return <RepeatIcon color='primary'/>;
                      case 6:
                        return <RepeatIcon color='error'/>;
                      case 7:
                        return <EngineeringIcon color='error'/>;
                      default:
                        return <MonetizationOnIcon color='inherit'/>;
                      }
                    })()}
                  </TableCell>
                  <TableCell align='center'>{dayjs(row.datalan).format('DD/MM/YYYY')}</TableCell>
                  <TableCell sx={{ maxWidth: 110, width:110 }} align='center'>{row.numero}</TableCell>
                  <TableCell align='left'>{row.nome}</TableCell>
                  <TableCell align='left'>{row.historico}</TableCell>
                  <TableCell align='right'>{row.entrada.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2})}</TableCell>
                  <TableCell align='right'>{row.saida.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2})}</TableCell>
                  <TableCell>
                    {(() => {
                      switch (row.status) {
                      case true:
                        return <Switch checked={row.status} />;
                      case false:
                        return <IconButton color='error' aria-label="add to shopping cart" size="small">
                          <ReplyIcon onClick={() => {handleVoltar(row.idoperacao, row.datalan, row.idconta, row.tipos);}} />
                        </IconButton>;
                      }
                    })()}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody> 
   
            {totalCount === 0 && !isLoading && (
              <caption>{Environment.LISTAGEM_VAZIA}</caption>
            )}
            <TableFooter>
              {isLoading && (
                <TableRow>
                  <TableCell colSpan={7}>
                    <LinearProgress variant='indeterminate' />
                  </TableCell>
                </TableRow>
              )}
              {(totalCount > 0 && totalCount > 12) && (
                <TableRow>
                  <TableCell colSpan={7}>
                    <Pagination  
                      page={pagina}
                      count={Math.ceil(totalCount / 12)}
                      onChange={(_, newPage) => setSearchParams({contaselect: contaselect.toString(), pagina: newPage.toString() }, { replace: true })}
                    />
                  </TableCell>
                </TableRow>
              )}
            </TableFooter>
          </Table>
        </Table>
      </TableContainer>
      <DialoFecharCaixa
        open={openFechar} 
        onClose={handleClose}
        idconta={Number(contaselect)}
      /> 
      <DialogAbrirCaixa
        open={openAbrir} 
        onClose={handleClose}
        idconta={Number(contaselect)}
      /> 
    </LayoutBaseDeFinanceiroConciliacao>
  ); 
};